import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {BIconCheck2Circle, BIconXCircle} from "bootstrap-vue";
import {TsxComponent} from "@/components/TsxComponent";
import AddressComponent, {AddressTitleType} from "@/components/structures/Address";
import {isEmpty} from "@/constants/DataBoundaries";
import CheckBox from "@/components/form/CheckBox";
import {DemandForm} from "@/_modules/demand/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {DemandComponentValidation} from "@/_modules/demand/components/DemandLayout";

interface Props {
  readonly localeRoot: string;
  readonly value: DemandForm;
  readonly v: ValidationInstance<DemandComponentValidation>;
}

@Component({name: 'DemandStep3', components: {BIconCheck2Circle, BIconXCircle}})
export default class DemandStep3 extends TsxComponent<Props> {
  @Prop(String) public readonly localeRoot!: string
  @Prop(Object) public readonly value!: DemandForm
  @Prop(Object) public readonly v!: ValidationInstance<DemandComponentValidation>

  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_service_address`)}</h2>
        <div class="mb-4 mt-6 my-md-10">
          <AddressComponent
            v-model={this.value.location}
            v={this.v.form.location}
            addressTitle={AddressTitleType.ServiceAddress}
            atClientPlace={this.value.requestedAtClientPlace}
            onChangeServicePlace={(v: boolean) => {this.value.requestedAtClientPlace = v}}
            servicePlaceTypeControl={true}
            addressAlreadyDefined={!Object.values(this.value.location.address).some(it => (isEmpty(it)))}
            isService={false}
          />
          <CheckBox
            class="mb-3"
            label={this.translation('fld_location_consent')}
            v-model={this.value.agreeToPublishAddress}
            invalid-message={this.buildInvalidMessage(this.v.form.agreeToPublishAddress!)}
          />
        </div>
      </div>
    )
  }
}
