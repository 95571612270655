import {Component, Watch, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import VueMultiSelect from "vue-multiselect"

import {BasicEvents} from "@/constants/ComponentEvents"
import { AbstractInputComponent } from "./AbstractInputComponent"

type ValueType = string[] | string | number | boolean | undefined

interface Props {
  readonly invalidMessage?: string;
  readonly label?: string;
  readonly options: object[];
  readonly searchable?: boolean;
  readonly textField?: string;
  readonly value: string[];
  readonly valueField?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly onInput?: (v: any[]) => void;
}

@Component({name: 'CheckboxMultiSelectInput', components: {VueMultiSelect}})
export default class extends AbstractInputComponent<ValueType, Props> {

  @Prop(Array) public readonly value!: string[]
  @Prop({type: Array, required: false, default: () => []}) public readonly options!: object[]
  @Prop({type: String, required: false, default: 'value'}) protected readonly valueField!: string
  @Prop({type: String, required: false, default: 'text'}) protected readonly textField!: string
  @Prop({type: Boolean, required: false, default: false}) protected readonly searchable!: boolean

  public selected: object[] = []
  public isOpen = false

  @Watch('value')
  public onValueChange(v: string[]): void {
    this.selectByKeys(v)
  }

  public onSelectInput(v: object[]): void {
    this.$emit(BasicEvents.Input, v.map(this.getValue))
  }

  private getValue(item: object): string | number {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const it = item as any
    return it[this.valueField]
  }

  private selectByKeys(keys: (string | number)[]): void {
    this.selected = this.selectOptions.filter(v => {
      return keys.includes(this.getValue(v))
    })
  }

  public get selectOptions(): object[] {
    return this.options
  }

  public isEmpty(): boolean {
    // Checkbox can't be empty
    return false
  }

  public get state(): boolean | undefined {
    return this.invalidMessage !== undefined ? false : undefined
  }

  public mounted(): void {
    this.onValueChange(this.value);
  }

  public render(): VNode {
    return (
      <b-form-group
        class="custom-control-multi-select"
        label={this.label}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        <VueMultiSelect
          class="custom-control-checkbox-select"
          close-on-select={false}
          deselect-label=""
          label={this.textField}
          multiple={true}
          options={this.selectOptions}
          placeholder=""
          preselect-first={false}
          searchable={this.searchable}
          select-label=""
          selected-label=""
          track-by={this.valueField}
          value={this.selected}
          onOpen={() => (this.isOpen = true)}
          onClose={() => (this.isOpen = false)}
          onInput={this.onSelectInput}
          scopedSlots={{
            selection: () => {
              if (!this.searchable || (this.searchable && !this.isOpen)) {
                return <span class="multiselect__single">{this.translation('shared.selected_count_alt', [this.value.length])}</span>
              }

              return ''
            },
            option: ({ option }: { option: { value: string; text: string } }): VNode => {
              return (
                <b-form-checkbox
                  class="mb-0"
                  readonly
                  checked={this.value.includes(option.value)}
                >
                  <span class="ml-n1">
                    {option.text}
                  </span>
                </b-form-checkbox>
              )
            }
          }}
        />
      </b-form-group>
    )
  }
}
