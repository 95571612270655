import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {BIconCheck2Circle, BIconXCircle} from "bootstrap-vue";
import {TsxComponent} from "@/components/TsxComponent";
import {DemandForm} from "@/_modules/demand/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {DemandComponentValidation} from "@/_modules/demand/components/DemandLayout";
import I18nOptions from "@/utils/I18nOptions";
import {ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, Sex} from "@/types";
import RadioGroupInput from "@/components/form/RadioGroupInput";
import CheckboxMultiSelectInput from "@/components/form/CheckboxMultiSelectInput";
import SingleSelectInput from "@/components/form/SingleSelectInput";

interface Props {
  readonly localeRoot: string;
  readonly value: DemandForm;
  readonly v: ValidationInstance<DemandComponentValidation>;
}

@Component({name: 'DemandStep2', components: {BIconCheck2Circle, BIconXCircle}})
export default class DemandStep2 extends TsxComponent<Props> {
  @Prop(String) public readonly localeRoot!: string
  @Prop(Object) public readonly value!: DemandForm
  @Prop(Object) public readonly v!: ValidationInstance<DemandComponentValidation>

  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_details`)}</h2>
        <p class="mb-6 mb-md-10">
          {this.translation(`${this.localeRoot}.subtitle_clients_parameters`)}
        </p>
        <b-row class="mb-4 mb-md-10">
          <b-col cols="12" md="6">
            <SingleSelectInput
              label={`${this.translation('shared.lbl-client-age')}*`}
              invalid-message={this.buildInvalidMessage(this.v.form.clientAge)}
              options={I18nOptions.buildEnumOptionsWithEmptyString(this.$i18n, Object.values(ClientAge), 'shared.enum-client-age-', true)}
              value={this.value.clientAge!}
              onInput={(value: ClientAge) => {
                this.value.clientAge = value;
              }}
            />
          </b-col>
          <b-col cols="12" md="6">
            <RadioGroupInput
              class="mb-3"
              radioClass="pt-md-3"
              label={`${this.translation('shared.lbl-client-sex')}*`}
              v-model={this.value.clientSex}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Sex), 'shared.enum-sex-type-', true)}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientSex)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              label={`${this.translation('shared.lbl-client-physical-activity')}*`}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientPhysicalActivity), 'shared.enum-client-physical-activity-', true)}
              value={this.value.clientPhysicalActivity}
              onInput={(v: ClientPhysicalActivity[]) => (this.value.clientPhysicalActivity = v)}
              invalidMessage={this.buildInvalidMessage(this.v.form.clientPhysicalActivity)}
            />
          </b-col>
          <b-col cols="12" md="6">
            <SingleSelectInput
              label={this.translation('shared.lbl-mental-ability')}
              invalid-message={this.buildInvalidMessage(this.v.form.clientMentalAbility)}
              options={I18nOptions.buildEnumOptionsWithEmptyString(this.$i18n, Object.values(ClientMentalAbility), 'shared.enum-client-mental-ability-', true)}
              value={this.value.clientMentalAbility!}
              onInput={(value: ClientMentalAbility) => {
                this.value.clientMentalAbility = value;
              }}
            />
          </b-col>
          <b-col cols="12" md="6">
            <SingleSelectInput
              label={`${this.translation('demand.fld-client-weight')}*`}
              invalid-message={this.buildInvalidMessage(this.v.form.clientWeight)}
              options={I18nOptions.buildEnumOptionsWithEmptyString(this.$i18n, Object.values(ClientWeight), 'shared.enum-client-weight-', true)}
              value={this.value.clientWeight!}
              onInput={(value: ClientWeight) => {
                this.value.clientWeight = value;
              }}
            />
          </b-col>
          <b-col cols="12" md="6">
            <CheckboxMultiSelectInput
              label={this.translation('shared.lbl-client-disease')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientDisease), 'shared.enum-client-disease-', true)}
              searchable
              value={this.value.clientDisease}
              onInput={(v: ClientDisease[]) => (this.value.clientDisease = v)}
            />
          </b-col>
        </b-row>
      </div>
    )
  }
}
