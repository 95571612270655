import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {BIconCheck2Circle, BIconXCircle} from "bootstrap-vue";
import {TsxComponent} from "@/components/TsxComponent";
import RadioGroupInput from "@/components/form/RadioGroupInput";
import I18nOptions from "@/utils/I18nOptions";
import {RateType} from "@/types";
import TextInput from "@/components/form/TextInput";
import {DemandForm} from "@/_modules/demand/types";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {DemandComponentValidation} from "@/_modules/demand/components/DemandLayout";
import CheckBox from "@/components/form/CheckBox";
import ToolTip from "@/components/layout/ToolTip";

interface Props {
  readonly localeRoot: string;
  readonly value: DemandForm;
  readonly v: ValidationInstance<DemandComponentValidation>;
  readonly onCurrencyInputFormatted: (amount: string) => void;
}

@Component({name: 'DemandStep4', components: {BIconCheck2Circle, BIconXCircle}})
export default class DemandStep4 extends TsxComponent<Props> {
  @Prop(String) public readonly localeRoot!: string
  @Prop(Object) public readonly value!: DemandForm
  @Prop(Object) public readonly v!: ValidationInstance<DemandComponentValidation>

  private formatCurrency(v: string): void {
    const formatted = v.replace(',', '.')
    this.$emit('currencyInputFormatted', formatted)
  }

  public render(): VNode {
    return (
      <div>
        <h2 class="fieldset-title mb-2">{this.translation(`${this.localeRoot}.wizard.title_conditions`)}</h2>
        <RadioGroupInput
          class="mt-6 mt-md-10 mb-3"
          radioClass="pt-md-3"
          label={this.translation('fld_demand_service_price')}
          options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(RateType), 'enum_services_rate_type_')}
          v-model={this.value.budget.type}
          invalid-message={this.buildInvalidMessage(this.v.form.budget.type)}
        />
        <b-row>
          <b-col cols="12" md="6">
            <TextInput
              label={this.translation('fld_demand_price')}
              v-model={this.value.budget.price.amount}
              value={this.value.budget.price.amount}
              onInput={(v: string) => this.formatCurrency(v)}
              invalid-message={this.buildInvalidMessage(this.v.form.budget.price.amount!)}
              custom-class="price-input border-right-0"
              append={(): VNode => {
                return (
                  <div class="input-group-text pl-0">{this.translation(`enum_currency_${this.value.budget.price.currency}`)}</div>
                )
              }}
            >
              <template slot="topDescription">
                {this.translation('fld_demand_price_description')}
              </template>
            </TextInput>
          </b-col>
        </b-row>
        <CheckBox
          checked={!this.value.visible}
          onChange={(v: boolean) => (this.value.visible = !v)}
        >
          <template slot="label">
            {this.translation('fld_hide_demand')}&nbsp;
            <ToolTip
              variant="primary"
              options={{title: this.translation('text_tooltip_hide_demand')}}
            />
          </template>
        </CheckBox>
      </div>
    )
  }
}
