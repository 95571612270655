import {DemandForm} from "@/_modules/demand/types";
import {Currency, ReusefulSearchFields} from "@/types";
import {defaultCountry} from "@/constants/ApplicationConfiguration";
import {mapStore} from "@/store/map";
import {buildReusefulSearchFields} from "@/_modules/shared-builders";

export interface DemandFindForm extends ReusefulSearchFields {
  totalNumberDemands?: number;
}

export function buildDemandForm(): DemandForm {
  return {
    userId: undefined,
    demandId: undefined,
    category: '',
    clientRequirements: [],
    title: undefined,
    demandDescription_ru: undefined,
    demandDescription_et: undefined,
    demandDescription_en: undefined,
    /*diseaseDescription_ru: undefined,
    diseaseDescription_et: undefined,
    diseaseDescription_en: undefined,*/
    languages: [],
    location: {
      address: {
        country: defaultCountry
      },
      coordinates: mapStore.lastPosition
    },
    budget: {
      price: {
        amount: undefined,
        currency: Currency.EUR
      }
    },
    uploadToken: undefined,
    requestedAtClientPlace: undefined,
    clientWeight: undefined,
    clientSex: undefined,
    agreeToPublishAddress: undefined,
    clientAge: undefined,
    clientMentalAbility: undefined,
    clientPhysicalActivity: [],
    clientDisease: [],
    visible: true
  }
}

export function buildDemandFindForm(): DemandFindForm {
  return {
    ...buildReusefulSearchFields(),
    totalNumberDemands: 0
  }
}
