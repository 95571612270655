import {AddressValidation, GeoLocationValidation} from "@/types";
import {required, requiredIf, sameAs} from "vuelidate/lib/validators";
import {buildValidationRules} from "@/utils/vuelidate-extension";
import DataBoundaries, {isEmpty, nonNegativeCurrencyValidator} from "@/constants/DataBoundaries";
import {DemandComponentValidation} from "@/_modules/demand/components/DemandLayout";
import {DemandForm} from "@/_modules/demand/types";

function addressValidation(): AddressValidation {
  return {
    country: {required},
    cityOrCounty: {required, ...buildValidationRules(DataBoundaries.city)},
    zipCode: {required, ...buildValidationRules(DataBoundaries.zipCode)},
    address: {required, ...buildValidationRules(DataBoundaries.address)}
  }
}

function locationValidation(): GeoLocationValidation {
  return {
    address: addressValidation(),
    coordinates: {}
  }
}

export function validationDemandRules(): DemandComponentValidation {
  return {
    form: {
      userId: {},
      demandId: {},
      category: {required},
      clientRequirements: {required},
      title: {required},
      demandDescription_ru: {required: requiredIf((obj: DemandForm) => (isEmpty(obj.demandDescription_en) && isEmpty(obj.demandDescription_et)))},
      demandDescription_et: {required: requiredIf((obj: DemandForm) => (isEmpty(obj.demandDescription_ru) && isEmpty(obj.demandDescription_en)))},
      demandDescription_en: {required: requiredIf((obj: DemandForm) => (isEmpty(obj.demandDescription_et) && isEmpty(obj.demandDescription_ru)))},
      languages: {required},
      location: locationValidation(),
      requestedAtClientPlace: {},
      budget: {
        type: {requiredIf: requiredIf((obj) => !isEmpty(obj.price.amount))},
        price: {
          amount: {
            nonNegativeCurrency: nonNegativeCurrencyValidator,
            ...buildValidationRules(DataBoundaries.currency),
            ...buildValidationRules(DataBoundaries.currencyNonNegative)
          },
          currency: {}
        }
      },
      visible: {},
      uploadToken: {},
      clientWeight: {required},
      clientSex: {required},
      clientAge: {required},
      agreeToPublishAddress: {
        sameAs: sameAs(() => true)
      },
      clientMentalAbility: {},
      clientPhysicalActivity: {required},
      clientDisease: {}
    }
  }
}
