import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { TsxComponent } from "@/components/TsxComponent";
import { ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, Language, Sex } from "@/types"
import { BIconSliders } from "bootstrap-vue";

import CheckBoxGroupFieldset from "@/components/form/CheckBoxGroupFieldset";
import AppIconClose from "@/components/icons/AppIconClose"
import I18nOptions from "@/utils/I18nOptions"
import { DemandFindForm } from "@/_modules/demand/form-builder";
import { ServiceTypeForSearch} from "@/_modules/service/types";
import { SearchModalFields } from "@/types";
import { buildSearchModalFields } from "@/_modules/shared-builders";

interface Props {
  readonly value: DemandFindForm;
  readonly onConfirm?: (value: DemandFindForm) => void;
  readonly onCancel?: () => void;
}

@Component({name: 'FindDemandFilterModal', components: { BIconSliders }})
export default class extends TsxComponent<Props> {
  @Prop(Object) public value!: DemandFindForm;

  private updatedValues: SearchModalFields = buildSearchModalFields();

  private servicePlaceType(value: ServiceTypeForSearch): void {
    switch (value) {
      case ServiceTypeForSearch.All:
        this.updatedValues.serviceAtHome = value
        break
      case ServiceTypeForSearch.ServiceAtHome:
        this.updatedValues.serviceAtHome = value
        break
      case ServiceTypeForSearch.NotServiceAtHome:
        this.updatedValues.serviceAtHome = value
        break
      default:
        break
    }
  }

  public render(): VNode {
    return (
      <div class="d-flex justify-content-end align-items-center flex-fill mb-6">
        <b-button 
          v-b-modal={'demand-filter-modal'}
          variant="link"
          class="py-1 border-0 px-0"
        >
          <b-icon-sliders class="app-icon-lg mr-2" />{this.translation('filter_modal_show')}
        </b-button>
        <b-modal
          cancel-title={this.translation('btn_reject')}
          cancel-variant="outline-primary"
          ok-title={this.translation('btn_confirm')}
          centered
          id="demand-filter-modal"
          scrollable
          size="xl"
          title={this.translation('demand_filter_modal_title')}
          title-tag="h2"
          onCancel={() => {
            this.$emit('cancel')
          }}
          onOk={() => {
            this.$emit('confirm', this.updatedValues)
          }}
        >
          <template slot="modal-header-close">
            <AppIconClose class="text-primary" />
          </template>
          <template slot="default">
            <CheckBoxGroupFieldset
              title={this.translation('fld_filter_by_language')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Language), 'enum_multiselect_language_')}
              value={this.value.speakLang}
              onChange={(v: Language[]) => {
                this.updatedValues.speakLang = v
              }}
            />
            <b-form-group>
              <legend class="h3 fieldset-title">{this.translation('fld_filter_by_service_location')}</legend>
              <b-form-radio-group
                stacked={true}
                checked={this.value.serviceAtHome}
                options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ServiceTypeForSearch), 'enum_service_type_for_search_')}
                size="md"
                onChange={(value: ServiceTypeForSearch) => this.servicePlaceType(value)}
              />
            </b-form-group>
            <CheckBoxGroupFieldset
              title={this.translation('shared.lbl-client-sex')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(Sex), 'shared.enum-sex-type-', true).filter(it => it.value !== Sex.Unset)}
              value={this.value.sex}
              onChange={(v: Sex[]) => {
                this.updatedValues.sex = v
              }}
            />
            <CheckBoxGroupFieldset
              title={this.translation('shared.lbl-client-age')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientAge), 'shared.enum-client-age-', true)}
              value={this.value.age}
              onChange={(v: ClientAge[]) => {
                this.updatedValues.age = v
              }}
            />
            <CheckBoxGroupFieldset
              title={this.translation('shared.lbl-weight')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientWeight), 'shared.enum-client-weight-', true)}
              value={this.value.weight}
              onChange={(v: ClientWeight[]) => {
                this.updatedValues.weight = v
              }}
            />
            <CheckBoxGroupFieldset
              title={this.translation('shared.lbl-client-physical-activity')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientPhysicalActivity), 'shared.enum-client-physical-activity-', true)}
              value={this.value.physical}
              onChange={(v: ClientPhysicalActivity[]) => {
                this.updatedValues.physical = v
              }}
            />
            <CheckBoxGroupFieldset
              title={this.translation('shared.lbl-client-mental-ability')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientMentalAbility), 'shared.enum-client-mental-ability-', true)}
              value={this.value.mental}
              onChange={(v: ClientMentalAbility[]) => {
                this.updatedValues.mental = v
              }}
            />
            <CheckBoxGroupFieldset
              title={this.translation('shared.lbl-client-disease-long')}
              options={I18nOptions.buildEnumOptions(this.$i18n, Object.values(ClientDisease), 'shared.enum-client-disease-', true)}
              value={this.value.disease}
              groupClass="mb-0"
              checkBoxClass="checkbox-columns"
              onChange={(v: ClientDisease[]) => {
                this.updatedValues.disease = v
              }}
            />
          </template>
        </b-modal>
      </div>
    )
  }
}
