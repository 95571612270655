import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconStar'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="app-icon" viewBox="0 0 24 24">
        <g clip-path="url(#clip0)">
          <path d="M5.186 22.72a.25.25 0 01-.28-.03.332.332 0 01-.117-.327l1.245-7.095.047-.264-.194-.184L.603 9.785a.34.34 0 01-.087-.35c.037-.126.126-.203.236-.218L8.1 8.174l.259-.037.117-.233 3.276-6.49c.11-.22.388-.22.498 0l3.276 6.49.118.233.258.037 7.347 1.044c.11.015.199.092.237.218a.34.34 0 01-.087.35l-5.284 5.034-.194.184.046.264 1.245 7.095a.332.332 0 01-.117.326.25.25 0 01-.281.03l-6.586-3.383-.23-.118-.228.118-6.583 3.384z" fill="currentColor"/>
          <path d="M5.186 22.72h0a.25.25 0 01-.28-.03.332.332 0 01-.117-.327h0l1.245-7.095.047-.264-.194-.184L.603 9.785a.34.34 0 01-.087-.35c.037-.126.126-.203.236-.218h0L8.1 8.174l.259-.037.117-.233 3.276-6.49c.11-.22.388-.22.498 0l3.276 6.49.118.233.258.037 7.347 1.044h0c.11.015.199.092.237.218a.34.34 0 01-.087.35l-5.284 5.034-.194.184.046.264 1.245 7.095h0a.332.332 0 01-.117.326.25.25 0 01-.281.03s0 0 0 0l-6.586-3.383-.23-.118-.228.118-6.583 3.384z" stroke="currentColor"/>
          <path d="M5.186 22.72h0a.25.25 0 01-.28-.03.332.332 0 01-.117-.327h0l1.245-7.095.047-.264-.194-.184L.603 9.785a.34.34 0 01-.087-.35c.037-.126.126-.203.236-.218h0L8.1 8.174l.259-.037.117-.233 3.276-6.49c.11-.22.388-.22.498 0l3.276 6.49.118.233.258.037 7.347 1.044h0c.11.015.199.092.237.218a.34.34 0 01-.087.35l-5.284 5.034-.194.184.046.264 1.245 7.095h0a.332.332 0 01-.117.326.25.25 0 01-.281.03s0 0 0 0l-6.586-3.383-.23-.118-.228.118-6.583 3.384z" stroke="#000" stroke-opacity=".35"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h24v24H0z"/>
          </clipPath>
        </defs>
      </svg>
    )
  }
}
