import {RequestDetails, ServerOccurrence, TaskDefinition, TaskInternalOccurrence} from '@/_modules/request/types'
import {
  ArrayPage,
  DealStatus,
  DiaryDataForm,
  InitialTaskData,
  OrderDirection,
  ReusefulSearchFields,
  SearchModalFields,
  SortFields
} from '@/types'
import moment from 'moment'
import {foundZoom, serverDateTimeFormat} from '@/constants/ApplicationConfiguration'
import {ServiceTypeForSearch} from '@/_modules/service/types'
import {mapStore, pageSize} from '@/store/map'
import _ from 'lodash'
import { ProposalDetails } from './proposal/types'
import { isEmpty } from '@/constants/DataBoundaries'

export function buildSearchModalFields(): SearchModalFields {
  return {
    speakLang: [],
    age: [],
    weight: [],
    disease: [],
    physical: [],
    mental: [],
    serviceAtHome: ServiceTypeForSearch.All
  }
}

export function buildReusefulSearchFields(): ReusefulSearchFields {
  return {
    ...buildSearchModalFields(),
    serviceTypeId: [],
    topLevelCategory: '',
    location: '',
    sex: [],
    order: {
      columnName: SortFields.PRICE,
      orderDirection: OrderDirection.ASC
    },
    page: new ArrayPage(1, pageSize),
    center: mapStore.lastPosition,
    zoom: foundZoom,
    distance: 0,
    existLocationCords: {
      dLon: undefined,
      dLat: undefined
    }
  }
}

export const internalEventId = 'internalEventId'

function bindTaskFrameColor(status?: string): string {
  if (status === DealStatus.DealConcluded
    || status === DealStatus.Confirmed
    || status === DealStatus.Completed
    || status === DealStatus.PaymentStarted
    || status === DealStatus.PaymentFailed
    || status === DealStatus.Paid
    || status === DealStatus.PaidToProvider) {
    return '#999'
  } else if (status === DealStatus.New || status === DealStatus.UnderDiscussion) {
    return '#ff9d20'
  } else {
    return '#8ECD16'
  }
}

interface InitialCalendarData {
  calendarId: string;
  category: string;
}

function initialCalendarData(): InitialCalendarData {
  return {
    calendarId: '0',
    category: 'time'
  }
}

export function buildTaskOccurrenceInternalData(parent: InitialTaskData, occurrence: ServerOccurrence): TaskInternalOccurrence {
  return {
    title: '', // TODO: set string later
    body: parent.instructions,
    start: moment(occurrence.start).toDate(),
    end: moment(occurrence.end).toDate(),
    bgColor: bindTaskFrameColor(parent.dealStatus),
    recurrenceRule: parent.recurrenceRule,
    state: {
      requesterUserId: parent.requesterUserId,
      requesterUserName: parent.requesterUserName,
      providerUserId: parent.providerUserId,
      providerUserName: parent.providerUserName,
      dealType: parent.dealType,
      dealStatus: parent.dealStatus,
      dealId: parent.dealId,
      dealName: parent.dealName,
      completed: occurrence.completed!,
      confirmed: occurrence.confirmed!,
      parentId: parent.id!,
      firstTaskStart: moment(parent.start).toDate(),
      reminder: parent.reminder
    }
  }
}

export function convertTasksSeriesToInternalUse(tasks: InitialTaskData[]): TaskDefinition[] {
  tasks.forEach(t => {
    const id = internalEventId + String(Math.random())
    if (t.id === undefined) {
      t.id = id
    }
  })

  return tasks.map((task) => {
    return {
      ...task,
      occurrences: task.occurrences!.map((serverOccurrenceData) => {
        return {
          ...initialCalendarData(),
          ...serverOccurrenceData,
          ...buildTaskOccurrenceInternalData(task, serverOccurrenceData)
        }
      })
    }
  })
}

export function addDealDataToTaskOccurrences(tasks: TaskDefinition[], deal: RequestDetails | ProposalDetails): TaskDefinition[] {
  tasks.map(ts => {
    const lastTaskEnd = ts.occurrences[ts.occurrences.length - 1].end

    ts.occurrences.map(o => {
      o.title = deal.title
      o.state.lastTaskEnd = lastTaskEnd
      o.state.providerUserName = `${deal.service.provider.name.first} ${deal.service.provider.name.last}`
      o.state.requesterUserName = `${deal.requester.name.first} ${deal.requester.name.last}`
      o.state.dealStatus = !isEmpty(deal.status) ? deal.status : DealStatus.New
    })
  })

  return tasks
}

export function removeEvent(event: TaskDefinition, eventsList: TaskDefinition[]): TaskDefinition[] {
  return eventsList.filter(it => {
    return it.id !== event.occurrences[0].state?.parentId
  })
}

export function addOrUpdateEvent(event: TaskDefinition, eventsList: TaskDefinition[]): TaskDefinition[] {
  // TODO: Uncomment serviceCategories when server side support is added
  if (event.occurrences[0].state?.parentId === undefined) {
    const id = internalEventId + String(Math.random())
    const newTask: TaskDefinition = {
      id: id,
      end: event.end,
      start: event.start,
      recurrenceRule: event.recurrenceRule,
      // serviceCategories: event.serviceCategories,
      occurrences: [{
        ...initialCalendarData(),
        start: moment(event.start).toDate(),
        end: moment(event.end).toDate(),
        body: event.occurrences![0].body,
        bgColor: event.occurrences[0].bgColor,
        title: event.occurrences[0].title,
        recurrenceRule: event.recurrenceRule,
        state: {
          completed: event.occurrences[0].state?.completed,
          confirmed: event.occurrences[0].state?.confirmed,
          parentId: id,
          firstTaskStart: moment(event.start).toDate(),
          reminder: event.occurrences[0].state.reminder
        }
      }]
    }
    eventsList.push(newTask)

    return eventsList
  }

  if (event.occurrences[0].state?.parentId) {
    const e = _.cloneDeep(event)
    return removeEvent(event, eventsList).concat([{
      id: e.id,
      end: e.end,
      start: e.start,
      recurrenceRule: e.recurrenceRule,
      // serviceCategories: event.serviceCategories,
      occurrences: [{
        ...initialCalendarData(),
        start: moment(e.start).toDate(),
        end: moment(e.end).toDate(),
        body: e.occurrences![0].body,
        bgColor: event.occurrences[0].bgColor,
        title: event.occurrences[0].title,
        recurrenceRule: event.recurrenceRule,
        state: {
          completed: event.occurrences[0].state?.completed,
          confirmed: event.occurrences[0].state?.confirmed,
          parentId: e.id!,
          firstTaskStart: moment(e.start).toDate(),
          reminder: e.occurrences[0].state.reminder
        }
      }]
    }])
  }

  return eventsList
}

export function convertTasksIntoServerFormat(taskList: TaskDefinition[]): InitialTaskData[] {
  return taskList.map(it => {
    return ({
      id: it.id?.startsWith(internalEventId) ? undefined : it.id,
      start: moment(it.start).format(serverDateTimeFormat),
      end: moment(it.end).format(serverDateTimeFormat),
      instructions: it.occurrences[0].body!,
      recurrenceRule: it.recurrenceRule,
      reminder: it.occurrences[0].state.reminder,
      serviceCategories: it.serviceCategories
    })
  })
}

export function buildDiaryFormInitialState(): DiaryDataForm {
  return {
    id: undefined,
    bodyTemperature: undefined,
    heartRate: undefined,
    bloodPressure: undefined,
    bloodSugarLevel: undefined,
    urineVolume: undefined,
    saturation: undefined,
    medications: [],
    notes: undefined,
    collectedAt: moment().format(),
    time: String(moment().locale('et').format('LT'))
  }
}
