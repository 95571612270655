import {} from "google-maps"
import HttpStatus from "http-status-codes"
import {MapCoordinates} from "@/types"
import { GmapCoordinates } from "@/store/map"
import {Vue} from "vue/types/vue";

type MapRef = Vue | Element | (Vue | Element)[] | undefined

export function coordsToLatLng(coordinates: MapCoordinates): google.maps.LatLngLiteral {
  return {
    lat: coordinates.latitude,
    lng: coordinates.longitude
  }
}

export function latLngToCoords(latLng: google.maps.LatLng): MapCoordinates {
  return {
    latitude: latLng.lat(),
    longitude: latLng.lng()
  }
}

export function displayRoute(from: MapCoordinates, to: MapCoordinates, mapRef: MapRef): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const map = (mapRef as any).$mapObject as google.maps.Map
  const directionsService = new google.maps.DirectionsService
  const directionsDisplay = new google.maps.DirectionsRenderer({
    suppressMarkers: true,
    polylineOptions: {
      icons: [{
        icon: {
          path: 0,
          scale: 3,
          fillOpacity: 1,
          fillColor: "#336fd6",
          strokeOpacity: 0,
          strokeWeight: 0
        },
        repeat: "10px"
      }],
      strokeOpacity: 0,
      clickable: false
    }
  })
  directionsDisplay.setMap(map)
  directionsService.route({
    origin: coordsToLatLng(from),
    destination: coordsToLatLng(to),
    travelMode: google.maps.TravelMode.WALKING
  }, (response, status) => {
    if (status === HttpStatus.getStatusText(HttpStatus.OK)) {
      directionsDisplay.setDirections(response)
    } else {
      console.error('Unable to get directions with status: ' + status, response)
    }
  })
}

// Promise is required to expect full map render on initial page load
export async function centerMapOnLocation(location: MapCoordinates, mapRef: MapRef): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const map = await ((mapRef as any).$mapPromise as Promise<google.maps.Map>)
  map.setCenter(coordsToLatLng(location))
}

function metersPerPx(latLng: google.maps.LatLng, zoom: number): number {
  const ratio = 156543.03392
  const halfRadius = 180
  return ratio * Math.cos(latLng.lat() * Math.PI / halfRadius) / Math.pow(2, zoom)
}

// Promise is required to expect full map render on initial page load
export async function calculateZoomRadius(mapRef: MapRef): Promise<number> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const map = await ((mapRef as any).$mapPromise as Promise<google.maps.Map>)
    const width = map.getDiv().clientWidth / 2
    const height = map.getDiv().clientHeight / 2
    const center = map.getCenter()
    const mpp = metersPerPx(center, map.getZoom())
    const radiusPercentage = 0.95
    const radiusInPx = (width < height ? width : height) * radiusPercentage
    return mpp * radiusInPx
  } catch (e) {
    // TODO: if click on cards from the list within less then 1s after map shift, get promise error, but no side effects 
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return 5000
  }
}

function degreesToRadians(degrees: number): number {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return degrees * (Math.PI / 180);
}

function radiansToDegrees(radians: number): number {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return radians * (180 / Math.PI);
}

// Calculate new coordinates from a known point, distance, and bearing
export function coordinatesFromDistanceBearing(latLng: GmapCoordinates, distance: number, bearing: number): GmapCoordinates {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const distanceInKM = distance / 1000; // Convert meters to kilometers
  const earthRadius = 6371; // Radius of the Earth
  const bearingRad = degreesToRadians(bearing); // convert bearing degrees to radians
  const startLatitudeRad = degreesToRadians(latLng.lat);
  const startLongitudeRad = degreesToRadians(latLng.lng);

  const endLatitudeRad = Math.asin((Math.sin(startLatitudeRad) * Math.cos(distanceInKM / earthRadius)) + (Math.cos(startLatitudeRad) * Math.sin(distanceInKM / earthRadius) * Math.cos(bearingRad)));
  const endLongitudeRad = startLongitudeRad + Math.atan2(Math.sin(bearingRad) * Math.sin(distanceInKM / earthRadius) * Math.cos(startLatitudeRad), Math.cos(distanceInKM / earthRadius) - (Math.sin(startLatitudeRad) * Math.sin(endLatitudeRad)));

  // Return coordinates in degrees
  return {
    lat: radiansToDegrees(endLatitudeRad), 
    lng: radiansToDegrees(endLongitudeRad)
  };
}

// Calculate distance in pixels between points on map
function pxDistanceBetweenPointsOnMap(map: google.maps.Map, point1: google.maps.Point, point2: google.maps.Point): number {
  const pixelSize = Math.pow(2, -map.getZoom());

  return Math.sqrt(((point1.x - point2.x) * (point1.x - point2.x)) + ((point1.y - point2.y) * (point1.y - point2.y))) / pixelSize;
}

// Calculate padding in pixels for map bounds
export function getMapBoundsPadding(map: google.maps.Map, latLng: GmapCoordinates): google.maps.Padding {
  const coordinatesOnDistanceRadius = new google.maps.LatLng(latLng);
  const mapNorthEastCoordinates = map.getBounds()!.getNorthEast();
  const pointOnCircle = map.getProjection()!.fromLatLngToPoint(coordinatesOnDistanceRadius);

  const pointOnMapTopEdge = map.getProjection()!.fromLatLngToPoint(new google.maps.LatLng({
    lat: mapNorthEastCoordinates.lat(),
    lng: latLng.lng
  }));
  const pointOnMapRightEdge = map.getProjection()!.fromLatLngToPoint(new google.maps.LatLng({
    lat: latLng.lat,
    lng: mapNorthEastCoordinates.lng()
  }));

  const topEdgeDistance = pxDistanceBetweenPointsOnMap(map, pointOnCircle, pointOnMapTopEdge);
  const rightEdgeDistance = pxDistanceBetweenPointsOnMap(map, pointOnCircle, pointOnMapRightEdge);

  return {
    top: topEdgeDistance,
    right: rightEdgeDistance,
    left: rightEdgeDistance,
    bottom: topEdgeDistance
  };
}

export const mapStyles: google.maps.MapTypeStyle[] = [
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.government",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit.station.bus",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  }
];
