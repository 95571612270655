import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

export type ValueType = string | number | boolean

interface PropsType extends AbstractInputPropsType<ValueType> {
  options: object[];
  valueField?: string;
  textField?: string;
  buttons?: boolean;
  stacked?: boolean;
  radioClass?: string;
}

@Component({name: "RadioGroupInput"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {

  @Prop([String, Number, Boolean]) public readonly value?: ValueType
  @Prop({type: Array, required: false, default: () => []}) public readonly options!: object[]
  @Prop({type: String, required: false, default: 'value'}) public readonly valueField!: string
  @Prop({type: String, required: false, default: 'text'}) public readonly textField!: string
  @Prop(Boolean) public readonly buttons!: boolean
  @Prop(Boolean) public readonly stacked?: boolean
  @Prop({type: String, default: undefined, required: false}) public readonly radioClass?: string

  public isEmpty(): boolean {
    return this.value === undefined
  }

  public render(): VNode {
    // TODO: Plaintext must show selected value as a text
    return (
      <b-form-group
        label-for={this.id}
        label={this.label}
        description={this.description}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        <b-form-radio-group
          class={this.radioClass}
          stacked={this.stacked}
          id={this.id}
          name={this.name}
          required={this.required}
          disabled={this.disabled}
          checked={this.value}
          options={this.options}
          value-field={this.valueField}
          text-field={this.textField}
          buttons={this.buttons}
          onInput={this.onInput}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      </b-form-group>
    )
  }
}