import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {BIcon, BIconQuestionCircle, BIconQuestionCircleFill} from "bootstrap-vue"
import {TsxComponent} from "@/components/TsxComponent"

const directives = {}
interface ToolTipOptions {
  html?: boolean;
  title: string;
}

interface Props {
  options: ToolTipOptions;
  scale?: number;
  verticalPosition?: number;
  horizontalPosition?: number;
  customClass?: string;
  variant?: string;
}

@Component({name: 'ToolTip', components: {BIcon, BIconQuestionCircle, BIconQuestionCircleFill}})
export default class extends TsxComponent<Props> implements Props {
  @Prop(Object) public readonly options!: ToolTipOptions;
  @Prop({type: Number, required: false, default: 1}) public readonly scale!: number
  @Prop({type: Number, required: false, default: 0}) public readonly verticalPosition!: number
  @Prop({type: Number, required: false, default: 0}) public readonly horizontalPosition!: number
  @Prop({type: String, required: false, default: 'tooltip-icon-default'}) public readonly customClass!: string
  @Prop({type: String, required: false, default: 'primary'}) public readonly variant!: string;

  public isHover: boolean = false;
  
  public handleHover(hovered: boolean): void {
    this.isHover = hovered;
  }
  
  public render(): VNode {
    return (
      <span
        class="position-relative"
        v-b-tooltip={{directives, ...this.options}}
      >
        <b-icon
          v-b-hover={this.handleHover}
          class={['tooltip-icon', this.customClass]}
          icon={this.isHover ? 'question-circle-fill' : 'question-circle'}
          scale={this.scale} 
          shift-v={this.verticalPosition} 
          shift-h={this.horizontalPosition} 
          variant={this.variant}
        />
      </span>
    )
  }
}