import {Component, Prop, Watch} from 'vue-property-decorator'
import {VNode} from 'vue'
import { BIconExclamationCircle } from 'bootstrap-vue'

import BackendClientMixin from "@/mixins/BackendClientMixin"
import TextInput from "@/components/form/TextInput"

import {mapStore} from "@/store/map"

import {AddressForm, GeoLocationForm, GeoLocationValidation, LocationFromResponse} from "@/types"
import {BasicEvents} from "@/constants/ComponentEvents"
import {ValidationInstance} from "@/utils/vuelidate-extension"
import {defaultMapCenter, defaultZoom, foundZoom} from "@/constants/ApplicationConfiguration"
import {coordsToLatLng, mapStyles} from "@/utils/map-utils"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GmapCustomMarker from 'vue2-gmap-custom-marker/gmap-custom-marker';

import {cloneDeep, isEqual} from "lodash"
import CheckBox from "@/components/form/CheckBox";
import {ValueType} from "@/components/form/RadioGroupInput";
import {isEmpty} from "@/constants/DataBoundaries";
import {RecursivePartial} from "@/utils/typescript-library-extensions";
import InfoNotice from '../layout/InfoNotice'

interface Props {
  value?: GeoLocationForm;
  v?: object;
  //onChange?: (v: AddressForm) => void;
}

export enum AddressTitleType {
  HomeAddress = 'home-address',
  ServiceAddress = 'service-address'
}

const debug = process.env.NODE_ENV !== "production"

const randomAddress = (zipCode: string | undefined): RecursivePartial<LocationFromResponse> => {
  const topLeftLatitude = 59.42946897465875
  const topLeftLongitude = 24.634432141742764
  const bottonRightLatitude = 59.388309801056565
  const bottonRightLongitude = 24.73450311082265
  const randomize = (topLeft: number, bottonRight: number): number => (Math.random() * ((topLeft - bottonRight) / 2.0)) + ((topLeft + bottonRight) / 2.0)
  return {
    latitude: randomize(topLeftLatitude, bottonRightLatitude),
    longitude: randomize(topLeftLongitude, bottonRightLongitude),
    address: {
      postcode: zipCode
    }
  }
}


@Component({
  name: "Address",
  components: {
    BIconExclamationCircle
  }
})
export default class AddressComponent extends BackendClientMixin implements Props {
  @Prop(Boolean) public readonly servicePlaceTypeControl?: boolean
  @Prop(Boolean) public readonly atClientPlace?: boolean
  @Prop(Object) public readonly value!: GeoLocationForm
  @Prop(Object) public readonly v?: ValidationInstance<GeoLocationValidation>
  @Prop({type: String, required: false, default: AddressTitleType.HomeAddress}) public readonly addressTitle?: string
  @Prop(Boolean) public readonly isService?: boolean
  @Prop(Boolean) public readonly addressAlreadyDefined?: boolean

  public zoom = defaultZoom
  public lastAddress: AddressForm | null = null

  @Watch("value.coordinates")
  public async onValueChange(v: LocationFromResponse): Promise<void> {
    if (v !== undefined && this.zoom !== foundZoom) {
      this.zoom = foundZoom
      if (this.addressAlreadyDefined === false) {
        await this.updateAddress()
      }
    }
  }

  public async updateAddress(): Promise<void> {
    if (isEqual(this.value.address, this.lastAddress)) {
      // Avoid repeated address resolutions
      return
    }
    this.lastAddress = cloneDeep(this.value.address)

    if (!isEmpty(this.value.address.address) && !isEmpty(this.value.address.cityOrCounty)) {
      const coordsFromMap = await this.withRequest(mapStore.resolveCoordinates(this.value.address), undefined, true)
      const coords = (coordsFromMap === undefined && debug) ? randomAddress(this.value.address.zipCode) : coordsFromMap
      if (coords !== undefined) {
        const result: GeoLocationForm = cloneDeep(this.value)
        result.coordinates = {
          latitude: Number(coords.latitude),
          longitude: Number(coords.longitude)
        }

        this.$emit(BasicEvents.Input, result)
      }
    }
  }

  private autocompleteAddress(addressData: google.maps.places.PlaceResult): void {
    let cityOrCounty = '';
    let country = '';
    let streetAddress = '';
    let zipCode = '';

    if (typeof addressData.address_components !== undefined) {
      addressData.address_components!.forEach(entry => {
        const addressComponentType = entry.types?.[0];
  
        switch (addressComponentType) {
          case "postal_code": {
            zipCode = entry.long_name;
            break;
          }
  
          case "street_number": {
            // Get street number for full street address
            streetAddress = entry.long_name;
            break;
          }
  
          case "route": {
            // Get street name for full street address
            streetAddress = `${entry.short_name} ${streetAddress}`;
            break;
          }
  
          case "locality": {
            cityOrCounty = entry.long_name;
            break;
          }
  
          case "administrative_area_level_1": {
            // Fallback to county if not in a city
            if (cityOrCounty.length === 0) {
              cityOrCounty = entry.short_name;
            }
  
            break;
          }
  
          case "country": {
            country = entry.short_name;
            break;
          }
  
          default: {
            break;
          }
        }
      });
    }

    if (addressData?.geometry?.location) {
      this.value.coordinates = {
        latitude: addressData.geometry.location.lat(),
        longitude: addressData.geometry.location.lng()
      }
    }

    this.value.address.address = streetAddress;
    this.value.address.cityOrCounty = cityOrCounty;
    this.value.address.country = country;
    this.value.address.zipCode = zipCode;
  }

  public onChange(v: ValueType | undefined): void {
    this.$emit(BasicEvents.Change, v)
  }

  public render(): VNode {
    return (
      <div class="address">
        {this.servicePlaceTypeControl === true &&
          <CheckBox
            class="mb-3"
            checked={this.atClientPlace}
            onChange={(v: boolean) => {this.$emit('changeServicePlace', v)}}
          >
            <template slot="label">
              {this.isService ? this.translation('lbl_service_at_home') : this.translation('lbl_demand_at_home')}
            </template>
          </CheckBox>
        }
        <fieldset class="mb-md-4">
          {this.addressTitle === AddressTitleType.HomeAddress &&
            <legend class="h3 fieldset-title">{this.translation('title_profile_settings_user_home_address')}</legend>
          }
          {this.addressTitle === AddressTitleType.ServiceAddress &&
            <div class="mt-md-2">
              {this.atClientPlace &&
                <div>
                  <h3 class="mb-4">{this.isService ? this.translation('sub_title_starting_point') : this.translation('lbl_address_of_service')}</h3>
                  <InfoNotice
                    class="mt-4 align-items-center"
                    icon="exclamation-circle"
                  >
                    {this.isService ? this.translation('msg_starting_point_description_of_service') : this.translation('msg_description_demand_address')}
                  </InfoNotice>
                </div>
              }
              {!this.atClientPlace &&
                <div>
                  <h3 class="mb-4">{this.isService ? this.translation('sub_title_address_of_service') : this.translation('sub_title_client_location')}</h3>
                  <InfoNotice
                    class="mt-4 align-items-center"
                    icon="exclamation-circle"
                  >
                    {this.isService ? this.translation('msg_service_address_description_of_service') : this.translation('msg_starting_point_description_of_demand')}
                  </InfoNotice>
                </div>
              }
            </div>
          }
          <b-row>
            <b-col cols="12" md="5" lg="4">
              <b-form-group
                label-for="address-autocomplete"
                label={this.translation('fld_profile_settings_user_autocomplete_address')}
              >
                <gmap-autocomplete
                  class="form-control"
                  id="address-autocomplete"
                  options={{
                    fields: ["address_components", "geometry"]
                  }}
                  placeholder={this.translation('fld_profile_settings_user_autocomplete_address_placeholder')}
                  onPlace_changed={this.autocompleteAddress}
                />
              </b-form-group>
              <TextInput
                label={`${this.translation('fld_profile_settings_user_home_address_city')}*`}
                v-model={this.value.address.cityOrCounty}
                invalid-message={this.buildInvalidMessage(this.v!.address.cityOrCounty)}
                onBlur={this.updateAddress}
              />
              <TextInput
                label={`${this.translation('fld_profile_settings_user_home_address')}*`}
                placeholder={this.translation('fld_profile_settings_user_home_address_placeholder')}
                v-model={this.value.address.address}
                invalid-message={this.buildInvalidMessage(this.v!.address.address)}
                onBlur={this.updateAddress}
              />
              <TextInput
                label={`${this.translation('fld_profile_settings_user_home_zipCode')}*`}
                placeholder={this.translation('fld_profile_settings_user_home_zipCode_placeholder')}
                v-model={this.value.address.zipCode}
                invalid-message={this.buildInvalidMessage(this.v!.address.zipCode)}
                onBlur={this.updateAddress}
              />
            </b-col>
            <b-col cols="12" md="7" lg="8" class="form-group">
              <div class="embed-responsive embed-responsive-16by9 h-100">
                <gmap-map
                  center={this.value.coordinates === undefined ? coordsToLatLng(mapStore.lastPosition) : coordsToLatLng(this.value.coordinates)}
                  zoom={this.zoom}
                  style="width: 100%; height: 100%"
                  options={{
                    scrollwheel: false,
                    minZoom: 6,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: mapStyles
                  }}
                  class="embed-responsive-item"
                >
                  <GmapCustomMarker
                    alignment="center"
                    marker={this.value.coordinates !== undefined ? coordsToLatLng(this.value.coordinates) : coordsToLatLng(defaultMapCenter)}
                  >
                    <b-img src={require('@/assets/images/icons/marker_home.svg')} />
                  </GmapCustomMarker>
                </gmap-map>
              </div>
            </b-col>
          </b-row>
        </fieldset>
      </div>
    )
  }
}
