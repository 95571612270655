import {Component, Watch, Prop} from "vue-property-decorator"
import {VNode} from "vue"

import VueMultiSelect from "vue-multiselect"

import {BasicEvents} from "@/constants/ComponentEvents"
import { AbstractInputComponent } from "./AbstractInputComponent"

type ValueType = string | boolean | undefined

interface Props {
  readonly invalidMessage?: string;
  readonly label?: string;
  readonly options: object[];
  readonly value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly onInput?: (v: any) => void;
}

@Component({name: 'SingleSelectInput', components: {VueMultiSelect}})
export default class extends AbstractInputComponent<ValueType, Props> {

  @Prop(String) public readonly value!: string
  @Prop({type: Array, required: false, default: () => []}) public readonly options!: object[]

  public selected?: object = {}

  public isOpen = false

  @Watch('value')
  public onValueChange(v: string): void {
    this.selectByKey(v)
  }

  public onSelectInput(value: object): void {
    this.$emit(BasicEvents.Input, this.getValue(value))
  }

  private getValue(item: object): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const it = item as any
    return it.value
  }

  private selectByKey(key: string): void {
    this.selected = this.selectOptions.find(item => this.getValue(item) === key)
  }

  public get selectOptions(): object[] {
    return this.options
  }

  public isEmpty(): boolean {
    // Checkbox can't be empty
    return false
  }

  public get state(): boolean | undefined {
    return this.invalidMessage !== undefined ? false : undefined
  }

  public mounted(): void {
    this.onValueChange(this.value);
  }

  public render(): VNode {
    return (
      <b-form-group
        label={this.label}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        <VueMultiSelect
          class="custom-control-single-select"
          multiple={false}
          close-on-select={true}
          preselect-first={false}
          searchable={false}
          show-labels={false}
          placeholder=""
          label={'text'}
          options={this.selectOptions}
          value={this.selected}
          track-by={'value'}
          onInput={this.onSelectInput}
          scopedSlots={{
            singleLabel: ({ option }: { option: { value: string; text: string } }): VNode => {
              return (
                <span>{option.text}</span>
              )
            }
          }}
        />
      </b-form-group>
    )
  }
}
