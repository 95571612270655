import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";
import DemandLayout from "@/_modules/demand/components/DemandLayout";

@Component({name: 'NewDemand'})
export default class Service extends TsxComponent<{}> {

  public render(): VNode {
    return (
      <DemandLayout />
    )
  }
}
