import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import WizardIcon, {WizardIconDefinition} from "@/components/Wizard/WizardIcon";
import {TsxComponent} from "@/components/TsxComponent";

interface ButtonDefinitions {
  title: string;
  icon: WizardIconDefinition;
}

interface Props {
  currentStep: number;
  localeRoot: string;
}

@Component({name: 'Wizard'})
export default class Wizard extends TsxComponent<Props> {
  @Prop(Number) public readonly currentStep!: number
  @Prop(String) public readonly localeRoot!: string

  private get steps(): ButtonDefinitions[] {
    return [
      {
        title: `${this.localeRoot}.wizard.title_basic_info`,
        icon: {
          content: '1',
          active: this.isStepActive(0),
          stepCompleted: this.isStepCompleted(0)
        }
      }, {
        title: `${this.localeRoot}.wizard.title_details`,
        icon: {
          content: '2',
          active: this.isStepActive(1),
          stepCompleted: this.isStepCompleted(1)
        }
      }, {
        title: `${this.localeRoot}.wizard.title_address`,
        icon: {
          content: '3',
          active: this.isStepActive(2),
          stepCompleted: this.isStepCompleted(2)
        }
      }, {
        title: `${this.localeRoot}.wizard.title_conditions`,
        icon: {
          content: '4',
          active: this.isStepActive(3),
          stepCompleted: this.isStepCompleted(3)
        }
      }
    ]
  }

  protected isStepActive(step: number): boolean {
    return this.currentStep === step
  }

  protected isStepCompleted(step: number): boolean {
    return this.currentStep > step
  }

  public render(): VNode {
    return (
      <div aria-hidden="true" class="overflow-hidden mb-6 mb-md-10">
        <div class="wizard d-flex justify-content-center">
          {this.steps.map((step) => {
            return (
              <div class={`d-flex flex-column align-items-center justify-content-end step ${step.icon.active ? 'active' : (step.icon.stepCompleted ? 'completed' : '')}`}>
                <p class="step-title text-center mb-2">
                  {this.translation(step.title)}
                </p>
                <WizardIcon icon={step.icon}/>
              </div>
            )})}
        </div>
      </div>
    )
  }
}
