import VueI18n from 'vue-i18n'

export interface SelectOptionsWithBoolean<T> {
  value?: T;
  text: string;
  checked?: boolean;
}

export interface SelectOption<T> {
  value?: T;
  text: string;
}

export default {
  buildOptions<T>(i18n: VueI18n, options: SelectOption<T>[]): SelectOption<T>[] {
    return options.map(option => {
      return {
        ...option,
        value: option.value,
        text: i18n.t(option.text) as string
      }
    })
  },

  buildEnumOptions(i18n: VueI18n, type: string[], messageKeyPrefix: string, keepCase?: boolean): SelectOption<string>[] {
    return this.buildOptions(i18n, type.map(item => {
      return {
        value: item,
        text: keepCase ? messageKeyPrefix + item : messageKeyPrefix + item.toLowerCase()
      }
    }))
  },

  buildEnumOptionsWithEmptyString(i18n: VueI18n, type: string[], messageKeyPrefix: string, keepCase?: boolean): SelectOption<string>[] {
    type.unshift('')
    return this.buildOptions(i18n, type.map(item => {
      return {
        value: item,
        text: keepCase ? messageKeyPrefix + item : messageKeyPrefix + item.toLowerCase()
      }
    }))
  }
}
