import {ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, DealStatus, FetchCriteria, GeoLocation, GeoLocationForm, GeoLocationValidation, Language, MapCoordinates, Name, Price, Rate, RateForm, RateValidation, Rating, Sex, SortFields, UserId} from "@/types"
import {RecursivePartial} from "@/utils/typescript-library-extensions";
import {ValidationObject} from "@/utils/vuelidate-extension";
import {ServiceType, TasksDates} from "@/_modules/service/types";
import {ExistLocationCords} from "@/store/map";

export enum DemandFilterFields {
  CATEGORY = "category",
  DISTANCE = "distance",
  SPEAKLANG = "speakLang",
  SERVICE_AT_HOME = "serviceAtHome",
  SEX = "sex",
  AGE = "age",
  WEIGHT = "weight",
  DISEASE = "disease",
  MENTAL = "mental",
  PHYSICAL = "physical"
}

export interface Demand {
  userId: string;
  demandId: string;
  category: string;
  clientRequirements: string[];
  title: string;
  demandDescription_ru?: string;
  demandDescription_et?: string;
  demandDescription_en?: string;
  languages: Language[];
  location: GeoLocation;
  budget?: Rate;
  visible?: boolean;
  uploadToken?: string;
  requestedAtClientPlace: boolean;
  clientWeight?: ClientWeight;
  clientSex: Sex;
  clientAge: ClientAge;
  agreeToPublishAddress?: boolean;
  clientMentalAbility?: ClientMentalAbility;
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientDisease: ClientDisease[];
}

export interface DemandForm extends RecursivePartial<Demand> {
  clientRequirements: string[];
  budget: RateForm;
  location: GeoLocationForm;
  languages: Language[];
  clientWeight: ClientWeight | undefined;
  clientSex: Sex | undefined;
  clientAge: ClientAge | undefined;
  clientMentalAbility: ClientMentalAbility | undefined;
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientDisease: ClientDisease[];
}

export interface DemandValidation extends ValidationObject<Demand> {
  budget: RateValidation;
  location: GeoLocationValidation;
}

export interface DemandCards {
  nr: number;
  size: number;
  items: DemandCard[];
}

export interface RequesterPublic {
  id: UserId;
  displayName: string;
  verified: boolean;
  rating: Rating;
  sex: Sex;
}

export interface DemandCard {
  requester: RequesterPublic;
  demand: DemandData;
  distanceInMeters?: number;
}

export interface RequesterProtected {
  userId: UserId;
  name: Name;
  verified: boolean;
  sex?: Sex;
  rating: Rating;
  languages: Language[];
  location: GeoLocation;
}

export interface DemandData extends RecursivePartial<Demand> {
  id: string;
  title: string;
  category: string;
  budget?: Rate;
  languages: Language[];
  coordinates: MapCoordinates;
  serviceAtHome: boolean;
  clientSex: Sex;
  clientWeight: ClientWeight;
}

export interface MyServices {
  name: string;
  id: string;
}

export interface OptionsWithCategoryName {
  subCategories: string[];
  category: string;
}

export interface DemandDetails {
  demand: Demand;
  dateCreated: Date;
  distanceInMeters: number;
  requester: RequesterProtected;
  myServices: MyServices[];
  clientRequirementsWithParent: RecursivePartial<ServiceType>[];
}

export interface DemandUpdate {
  demandId: string;
  data: Demand;
}

export interface ProposalShortDetails {
  id: string;
  name: string;
  providerName: Name;
  tasksDates: TasksDates[];
  totalPrice: Price;
  status: DealStatus;
}

export interface DemandSummary {
  id: string;
  name: string;
  createdAt: string;
  active: string;
  proposalsCount: number;
  proposals: ProposalShortDetails[];
}

export interface DemandsSummary {
  items: DemandSummary[];
  nr: number;
  size: number;
  total: number;
}

export interface DemandCriteria extends FetchCriteria<SortFields, DemandFilterFields> {
  coordinates?: MapCoordinates;
  existLocationCords?: ExistLocationCords;
}

export interface DemandDetailsPayload {
  lat: number;
  lon: number;
  demandId: string;
}
