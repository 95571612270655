import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { BIcon, BIconInfoCircle } from "bootstrap-vue"
import { TsxComponent } from "../TsxComponent"

interface Props {
  variant?: string;
  icon?: string;
}

@Component({name: 'InfoNotice', components: { BIcon, BIconInfoCircle }})
export default class extends TsxComponent<Props> implements Props {
  @Prop({ type: String, default: 'primary' }) public readonly variant!: string;
  @Prop({ type: String, default: 'info-circle' }) public readonly icon!: string;

  public render(): VNode {
    return (
      <div class="d-flex mb-6">
        <b-icon icon={this.icon} variant={this.variant} class="app-icon-lg mt-0" />
        <div class="ml-3 mb-0 small">
          {this.$slots.default}
        </div>
      </div>
    )
  }
}
