import {Component} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import Button from "@/components/form/Button"
import { BIcon, BIconChatDots, BIconGeoAlt, BIconGeoAltFill, BIconPaperclip, BIconPatchQuestionFill, BIconStar, BIconTag } from "bootstrap-vue"

import {appStore, authStore} from "@/store"
import {mapStore} from "@/store/map"
import {demandStore} from "@/_modules/demand/store/demands"

import {centerMapOnLocation, coordsToLatLng, displayRoute, mapStyles} from "@/utils/map-utils"
import {distanceFormatter, formatDate} from "@/utils/formatters"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as Error from "@/utils/errors"
import {FileData, Language, MapCoordinates, parseQueryParamCoordinates, RateType} from "@/types"
import {DemandDetails, DemandDetailsPayload} from "@/_modules/demand/types"

import {BackendUrls, ErrorCodes} from "@/constants/APIconstants"
import {defaultMapCenter, disablePayedServices, foundZoom} from "@/constants/ApplicationConfiguration"

import * as RequestDemandRoutes from "@/_modules/proposal/router/proposal-demand-routes"
import * as ProfileRoutes from "@/_modules/profile/router/routes"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: No typescript declarations
import GmapCustomMarker from 'vue2-gmap-custom-marker/gmap-custom-marker';

import AttentionPopup from "@/components/layout/AttentionPopup"
import {profileStore} from "@/_modules/profile/store/profile"
import {PersonalSettings} from "@/_modules/profile/types"
import {RecursivePartial} from "@/utils/typescript-library-extensions"
import MultiLangTabs from '@/components/MultiLangTabs'
import DemandDetailsClientParameters from "@/_modules/demand/components/DemandDetailsClientParameters";
import { DemandFind } from "../router/routes";
import PageHeader from "@/components/layout/PageHeader";
import AppIconVerified from "@/components/icons/AppIconVerified";
import AppIconStar from "@/components/icons/AppIconStar";
import { isNumericEmpty } from "@/constants/DataBoundaries";
import AppIconGenderAmbiguous from "@/components/icons/AppIconGenderAmbiguous";
import AppIconScale from "@/components/icons/AppIconScale";
import ContentSection from "@/components/layout/ContentSection"

export class DemandErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.DemandNotFound:
        this.errorMessageKey = "err_demand_not_found"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const demandErrorHandler = new DemandErrorHandler()

@Component({
  name: 'DemandDetails',
  components: {
    BIcon,
    BIconChatDots,
    BIconGeoAlt,
    BIconGeoAltFill,
    BIconPaperclip,
    BIconPatchQuestionFill,
    BIconStar,
    BIconTag
  }
})
export default class extends BackendClientMixin {

  private userProfile: RecursivePartial<PersonalSettings> = {
    profile: undefined,
    payment: undefined
  }
  private showAttention: boolean = false

  public files: FileData[] = []
  public demandDetails: DemandDetails | null = null

  public coords: MapCoordinates = defaultMapCenter

  public get isRelativeServiceExist(): boolean {
    return (this.demandDetails !== null && this.demandDetails.myServices?.length > 0)
  }

  protected get isAccessToProposal(): boolean {
    return !this.isOwnDemand && this.isIbanExist && this.isProfileFilledUp
  }

  protected get isOwnDemand(): boolean {
    return this.demandDetails?.demand.userId === authStore.authInfo?.userId
  }

  protected get isProfileFilledUp(): boolean {
    return this.userProfile.profile?.name?.last !== undefined &&
      this.userProfile.profile.location?.address?.address !== undefined
  }

  protected get isIbanExist(): boolean {
    return this.userProfile.payment?.iban !== undefined
  }

  public get userLocation(): MapCoordinates | undefined {
    const currentLocation = parseQueryParamCoordinates(this.$route.query)
    if (currentLocation?.latitude === defaultMapCenter.latitude && currentLocation.longitude === defaultMapCenter.longitude) {
      return undefined
    } else {
      return currentLocation
    }
  }

  public get formattedPrice(): string {
    const budget = this.demandDetails?.demand.budget
    return `${budget?.price.amount ? budget?.price.amount : '-'} ${this.translation(`enum_currency_${budget?.price.currency}`)}${budget?.type === RateType.HOURLY_PAYMENT ? this.translation('text_hour_price') : ''}`
  }

  public async mounted(): Promise<void> {
    try {
      const demandId = this.$route.params.demandId
      const latitude = isNaN(Number(this.$route.query.latitude)) ? appStore.demandCoords.latitude : Number(this.$route.query.latitude)
      const longitude = isNaN(Number(this.$route.query.longitude)) ? appStore.demandCoords.longitude : Number(this.$route.query.longitude)

      const demandDetailsPayload: DemandDetailsPayload = {
        demandId: demandId,
        lat: latitude,
        lon: longitude
      }

      const resp = await this.withRequest(demandStore.getDemandDetailsById(demandDetailsPayload), demandErrorHandler)
      this.demandDetails = resp
      if (resp.demand.uploadToken !== undefined) {
        this.files = await this.withRequest(demandStore.getUploadedFiles(resp.demand.uploadToken), demandErrorHandler)
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No typescript declarations
      await this.$gmapApiPromiseLazy();

      const minimumServiceDistance = 50

      if (this.demandDetails?.distanceInMeters < minimumServiceDistance) {
        centerMapOnLocation(resp.demand.location.coordinates, this.$refs.mapRef)
      } else {
        if (this.userLocation === undefined) {
          if (this.demandDetails.demand.requestedAtClientPlace) {
            displayRoute(appStore.demandCoords, resp.demand.location.coordinates, this.$refs.mapRef)
  
          } else {
            displayRoute(resp.demand.location.coordinates, appStore.demandCoords, this.$refs.mapRef)
          }
        }
  
        if (!disablePayedServices && this.userLocation !== undefined) {
          if (this.demandDetails.demand.requestedAtClientPlace) {
            displayRoute(this.userLocation, resp.demand.location.coordinates, this.$refs.mapRef)

          } else {
            displayRoute(resp.demand.location.coordinates, this.userLocation, this.$refs.mapRef)
          }
        }
      }
    } catch(err) {
      this.errorMessageKey = "err_unexpected_error"
    }
    this.userProfile = await this.withRequest(profileStore.getSettings())
  }

  public buildHeader(): VNode {
    return (
      <b-row>
        {this.demandDetails && 
          <PageHeader
            headerClass="mt-4 mb-10"
            leadClass="text-sm"
            title={this.demandDetails.demand.title}
            wideHeader={true}
          >
            {this.translation('lbl_service_created')}: {formatDate(this.demandDetails.dateCreated)}
          </PageHeader>
        }
      </b-row>
    )
  }

  public async toProposalCreate(serviceId?: string): Promise<void> {

    const profile = await this.withRequest(profileStore.getProfile(authStore.authInfo!.userId))
    if (profile !== undefined && !this.isOwnDemand) {
      if (Object.keys(this.$route.query).length > 0) {
        this.coords = {
          latitude: Number(this.$route.query.latitude),
          longitude: Number(this.$route.query.longitude)
        }
        appStore.setDemandDetailsCoords(this.coords)
      }

      if (this.isAccessToProposal) {
        if (serviceId === undefined) {
          this.$router.push({
            path: `${RequestDemandRoutes.ProposalDraft.path}/${this.$route.params.demandId}`
          })
        } else {
          this.$router.push({
            path: `${RequestDemandRoutes.ProposalDraft.path}/${this.$route.params.demandId}`,
            query: {
              serviceId: serviceId
            }
          })
        }
      } else {
        this.showAttention = true
      }
    } else {
      this.showAttention = true
    }
  }

  public buildMainSection(): VNode {
    return (
      <div class="map-section">
        <b-row>
          <b-col cols="12" md="7" lg="8">
            <div class="embed-responsive embed-responsive-4by3 h-100">
              <gmap-map
                ref="mapRef"
                center={this.demandDetails === null ? coordsToLatLng(mapStore.lastPosition) : coordsToLatLng(this.demandDetails.demand.location.coordinates)}
                zoom={foundZoom}
                class="embed-responsive-item"
                options={{
                  scrollwheel: false,
                  minZoom: 6,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  styles: mapStyles
                }}
              >
                {this.demandDetails !== null && (this.userLocation === undefined || disablePayedServices) &&
                <gmap-marker
                  position={coordsToLatLng(this.demandDetails?.demand.location.coordinates)}
                />
                }
                {this.demandDetails !== null && this.userLocation !== undefined && !disablePayedServices &&
                <GmapCustomMarker
                  alignment="center"
                  marker={coordsToLatLng(this.userLocation)}
                >
                  <b-img src={require('@/assets/images/icons/marker_home.svg')} />
                </GmapCustomMarker>
                }
                {this.demandDetails?.demand.location.coordinates !== null && !disablePayedServices &&
                <GmapCustomMarker
                  alignment="center"
                  marker={coordsToLatLng(this.demandDetails!.demand.location.coordinates)}
                >
                  <b-badge
                    href="#"
                    class="app-gmap-custom-marker"
                    variant="primary"
                    pill
                  >
                    {distanceFormatter(this.demandDetails!.distanceInMeters, this.translation('text_m'), this.translation('text_km'))}
                  </b-badge>
                </GmapCustomMarker>
                }
              </gmap-map>
            </div>
          </b-col>
          <b-col cols="12" md="5" lg="4">
            <div class="p-6 pl-md-0">
              <p class="mb-0 h6">{this.translation('title_requester')}</p>
              <h2 class="mt-0 mb-3">{this.demandDetails?.requester.name?.first} {this.demandDetails?.requester.name?.last[0]}.</h2>
              <div class="d-flex mb-4">
                {this.demandDetails?.requester.verified &&
                  <span class="small text-nowrap mr-4 d-flex align-items-center">
                    <AppIconVerified class="app-icon-lg mt-0 align-top mr-2 text-success" />
                    {this.translation('msg_param_verified_true')}
                  </span>
                }
                {!this.demandDetails?.requester.verified &&
                  <span class="small text-nowrap mr-4 d-flex align-items-center">
                    <b-icon-patch-question-fill class="app-icon-lg mt-0 align-top mr-2" variant="danger-dark" />
                    {this.translation('msg_param_verified_false')}
                  </span>
                }
                {(!isNumericEmpty(this.demandDetails?.requester.rating.ratingsCount) && this.demandDetails!.requester.rating.ratingsCount !== 0) &&
                  <span class="small text-nowrap d-flex align-items-center">
                    <AppIconStar class="app-icon-lg mt-0 align-top mr-2 text-warning" aria-label={this.translation('lbl_rating')} />
                    {this.demandDetails?.requester.rating.grade} ({this.demandDetails?.requester.rating.ratingsCount})
                  </span>
                }
                {(isNumericEmpty(this.demandDetails?.requester.rating.ratingsCount) || this.demandDetails?.requester.rating.ratingsCount === 0) &&
                  <span class="small text-nowrap d-flex align-items-center">
                    <b-icon-star class="app-icon-lg mt-0 align-top mr-2 text-gray-400" aria-hidden={true} />
                    {this.translation('lbl_no_rating')}
                  </span>
                }
              </div>
              <hr class="w-100 mt-0 mb-6" />
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={this.demandDetails?.demand.requestedAtClientPlace ? 'geo-alt' : 'geo-alt-fill'} class={`app-icon-lg align-top mt-0${this.demandDetails?.demand.requestedAtClientPlace ? ' text-gray-500' : ' text-success'}`} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_demand_distance')}</p>
                  <span>{distanceFormatter(this.demandDetails?.distanceInMeters, this.translation('text_m'), this.translation('text_km'))}. {this.demandDetails?.demand.requestedAtClientPlace ? this.translation('msg_service_at_requester_location') : this.translation('msg_service_at_provider_location')}</span>
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'chat-dots'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_demand_languages')}</p>
                  <span>
                    {this.demandDetails?.demand.languages.map((lang, index) => {
                      return (
                        lang === Language.ASE ?
                          <span>
                            <b-link class="text-primary" v-b-tooltip={{title: this.translation('enum_language_user_speak_ase')}}>
                              {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}
                            </b-link>{index !== this.demandDetails!.demand.languages.length - 1 ? ', ' : ''}
                          </span> :
                          <span>
                            {this.translation('enum_language_user_speak_short_' + lang.toLowerCase())}{index !== this.demandDetails!.demand.languages.length - 1 ? ', ' : ''}
                          </span>
                      )
                    })}
                  </span>
                </div>
              </div>
              {this.demandDetails?.demand.clientSex &&
                <div class="d-flex mb-4">
                  <div class="flex-grow-0 mr-3">
                    <AppIconGenderAmbiguous class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                  </div>
                  <div>
                    <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_sex')}</p>
                    <span>{this.translation(`enum_sex_type_${this.demandDetails?.demand.clientSex.toLowerCase()}`)}</span>
                  </div>
                </div>
              }
              {this.demandDetails?.demand.clientWeight &&
                <div class="d-flex mb-4">
                  <div class="flex-grow-0 mr-3">
                    <AppIconScale class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                  </div>
                  <div>
                    <p class="small mb-0 font-weight-bold">{this.translation('lbl_client_weight')}</p>
                    <span>{this.translation(`shared.enum-client-weight-${this.demandDetails.demand.clientWeight}`)}</span>
                  </div>
                </div>
              }
              <div class="d-flex">
                <div class="flex-grow-0 mr-3">
                  <b-icon icon={'tag'} class={'app-icon-lg align-top mt-0 text-gray-500'} aria-hidden={true} />
                </div>
                <div>
                  <p class="small mb-0 font-weight-bold">{this.translation('lbl_demand_overage_budget')}</p>
                  <span>{this.formattedPrice}</span>
                </div>
              </div>
              {!this.isRelativeServiceExist &&
                <Button
                  variant="primary"
                  class="w-100 mt-6"
                  label={this.translation("btn_to_proposal_create_new")}
                  onClick={() => this.toProposalCreate()}
                />
              }
              {this.isRelativeServiceExist &&
                <b-dropdown
                  class="w-100 mt-6"
                  menu-class="shadow"
                  right
                  text={this.translation('btn_to_proposal_create')}
                  toggle-class="justify-content-center btn-start-query"
                  variant="primary"
                >
                  <b-dropdown-item onClick={() => this.toProposalCreate()}>
                    {this.translation('btn_to_proposal_create_new')}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-text>
                    <span class="small text-nowrap">{this.translation('lbl_request_pre_filling_description')}</span>
                  </b-dropdown-text>
                  <b-dropdown-divider />
                  {this.demandDetails?.myServices.map((it) => {
                    return (
                      <b-dropdown-item onClick={() => this.toProposalCreate(it.id)}>
                        {it.name}
                      </b-dropdown-item>
                    )
                  })}
                </b-dropdown>
              }
            </div>
          </b-col>
        </b-row>
      </div>
    )
  }

  public buildDescriptionSection(): VNode {
    const demandDescription = {
      demandDescription_ru: this.demandDetails?.demand.demandDescription_ru,
      demandDescription_et: this.demandDetails?.demand.demandDescription_et,
      demandDescription_en: this.demandDetails?.demand.demandDescription_en
    }

    return (
      <b-row class="justify-content-center mt-10 mt-md-16">
        {this.demandDetails !== null &&
          <b-col cols="12" lg="10">
            <ContentSection>
              <div class="mb-2 mb-sm-0">
                <h2 class="fieldset-title">{this.translation('title_request_description')}</h2>
                <div class="pl-md-4">
                  <MultiLangTabs
                    value={{
                      demandDescription_ru: demandDescription.demandDescription_ru,
                      demandDescription_et: demandDescription.demandDescription_et,
                      demandDescription_en: demandDescription.demandDescription_en
                    }}
                  />
                </div>
                <DemandDetailsClientParameters
                  value={this.demandDetails.demand}
                  optionsWithCategoryName={this.demandDetails.clientRequirementsWithParent}
                />
                {this.files.length > 0 && 
                  <h2 class="fieldset-title">{this.translation('title_demand_attachments')}</h2>
                }
                {this.files.length > 0 && 
                  <div class="pl-md-4">
                    {!this.busy && this.files.map(file => {
                      return file.public ? (
                        <div class="mt-2">
                          <b-link class="d-flex py-1 text-primary" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(file.id)}`}>
                            <b-icon-paperclip class="app-icon-lg mr-2"/>
                            {file.fileName}
                          </b-link>
                        </div>
                      ) : ''
                    })}
                  </div>
                }
              </div>
            </ContentSection>
          </b-col>
        }
      </b-row>
    )
  }

  public attentionMessage(): VNode {
    return (
      <div>
        {this.isIbanExist && !this.isOwnDemand && <span>{this.translation( 'msg_proposal_attention_user_does_not_have_profile')}</span>}
        {!this.isIbanExist && !this.isOwnDemand && <span>{this.translation('msg_proposal_attention_iban_not_exist')}</span>}
      </div>
    )
  }

  public attentionMessageTitleKey(): string | undefined {
    if (this.isOwnDemand) {
      return 'msg_attention_attempt_to_request_for_own_demand'
    } else if (!this.isIbanExist) {
      return 'msg_proposal_attention_iban_not_exist_title'
    }

    return undefined
  }

  public render(): VNode {
    return (
      <b-container fluid="xl" class="demand-details">
        {this.errorMessageKey === null && this.demandDetails &&
          <div>
            {this.buildHeader()}
            {this.buildMainSection()}
            {this.buildDescriptionSection()}
          </div>
        }
        <AttentionPopup
          titleKey={this.attentionMessageTitleKey()}
          message={this.attentionMessage}
          btnNextLabel={this.isOwnDemand ? 'btn_to_search' : 'btn_change_profile'}
          btnNextPath={this.isOwnDemand ? DemandFind.path : ProfileRoutes.Profile.path}
          btnNext={true}
          btnBack={false}
          btnClose={true}
          show={this.showAttention}
          onCancel={() => (this.showAttention = false)}
        />
      </b-container>
    )
  }
}
