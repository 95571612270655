import {Component, Prop} from 'vue-property-decorator'
import {VNode} from "vue"

import Button from "@/components/form/Button"

import {BIconArrowRight} from "bootstrap-vue"
import * as Routes from "@/router/routes"
import {TsxComponent} from "@/components/TsxComponent";
import AppIconClose from '../icons/AppIconClose'

interface Props {
  message?: () => VNode;
  btnNextLabel?: string;
  btnNextPath?: string;
  btnNext: boolean;
  btnNextAltLabel?: string;
  btnNextAltPath?: string;
  btnNextAlt?: boolean;
  btnBack: boolean;
  btnClose: boolean;
  show: boolean;
  titleKey?: string;
  onCancel? (e: Event): void;
}

@Component({name: "AttentionPopup", components: {BIconArrowRight}})
export default class extends TsxComponent<Props> implements Props {

  @Prop({type: Function, required: false}) public readonly message?: () => VNode
  @Prop({type: String, required: false, default: 'btn_home'}) public readonly btnNextLabel!: string
  @Prop({type: String, required: false, default: Routes.Home.path}) public readonly btnNextPath!: string
  @Prop({type: Boolean, required: true}) public readonly btnNext!: boolean
  @Prop({type: String, required: false}) public readonly btnNextAltLabel!: string
  @Prop({type: String, required: false, default: Routes.Home.path}) public readonly btnNextAltPath!: string
  @Prop({type: Boolean, required: false, default: false}) public readonly btnNextAlt!: boolean
  @Prop({type: Boolean, required: true}) public readonly btnBack!: boolean
  @Prop({type: Boolean, required: true}) public readonly btnClose!: boolean
  @Prop({type: Boolean, required: false, default: false}) public readonly show!: boolean
  @Prop({type: String, required: false, default: 'title_attention'}) public readonly titleKey!: string

  private confirmed: boolean = false

  public mounted(): void {
    if (this.btnBack && this.btnClose) {
      throw new Error(`btn Back and btn Close cannot be rendered in same time -> this.btnBack: ${this.btnBack} && this.btnClose: ${this.btnClose}`)
    }
  }

  public render(): VNode {
    if (!this.show) {
      return (<div/>)
    } else {
      return (
        <b-modal
          centered
          scrollable
          body-class='pt-14 px-10 pb-16 text-center'
          size="sm"
          hide-header={true}
          hide-footer={true}
          visible={this.show}
          no-close-on-backdrop={this.btnClose ? false : true} // Disable closing by clicking outside when close button is hidden
          no-close-on-esc={this.btnClose ? false : true} // Disable closing by pressing ESC when close button is hidden
          onHidden={() => {
            if (!this.confirmed) {
              this.$emit('cancel')
            }

            this.confirmed = false
          }}
          scopedSlots={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            default: ({ hide }: { hide: any }) => {
              return (
                <div>
                  
                  {(this.btnClose && !this.btnBack) &&
                    <b-link class="modal-dialog-close d-block" aria-label={this.translation('btn_cancel')} onClick={() => hide('forget')}>
                      <AppIconClose class="text-primary" />
                    </b-link>
                  }
                  <h3 class="mt-0">{this.translation(this.titleKey)}</h3>
                  {this?.message && this.message()}
                  <b-row class="mt-6 justify-content-center">
                    {(this.btnBack && !this.btnClose) &&
                      <div class="mr-2">
                        <Button variant="outline-primary" onClick={() => this.$router.go(-1)}>
                          <template slot="label">
                            {this.translation('btn_back')}
                          </template>
                        </Button>
                      </div>
                    }
                    <div>
                      {this.btnNext &&
                          <Button class={this.btnNextAlt ? 'mr-2' : ''} variant="primary" to={this.btnNextPath} onClick={() => {this.confirmed = true}}>
                            <template slot="label">
                              {this.translation(this.btnNextLabel)}
                            </template>
                          </Button>
                      }
                      {this.btnNextAlt &&
                          <Button variant="outline-primary" to={this.btnNextAltPath} onClick={() => {this.confirmed = true}}>
                            <template slot="label">
                              {this.translation(this.btnNextAltLabel)}
                            </template>
                          </Button>
                      }
                    </div>
                  </b-row>
                </div>
              )
            }
          }}
        >
        </b-modal>
      )
    }
  }
}
