import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconScale'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg class="app-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M15.636 14l-.463.59a.75.75 0 001.035-.105L15.636 14zm-7.273 0l-.571.485a.75.75 0 001.035.105L8.363 14zM4 8.857l-.612-.433a.75.75 0 00.04.918L4 8.857zm16 0l.572.485a.75.75 0 00.04-.918L20 8.857zm-8.75 3.429a.75.75 0 001.5 0h-1.5zm1.5-3.429a.75.75 0 00-1.5 0h1.5zM1.75 19V5H.25v14h1.5zM5 1.75h14V.25H5v1.5zM22.25 5v14h1.5V5h-1.5zM19 22.25H5v1.5h14v-1.5zm-2.9-8.84c-2.454-1.928-5.746-1.928-8.2 0l.927 1.18c1.91-1.5 4.436-1.5 6.345 0l.927-1.18zm-7.165.105L4.572 8.372l-1.144.97 4.364 5.143 1.143-.97zM4.612 9.29C6.262 6.956 9.102 5.75 12 5.75c2.898 0 5.737 1.206 7.388 3.54l1.224-.866C18.626 5.616 15.283 4.25 12 4.25c-3.284 0-6.627 1.366-8.612 4.174l1.224.866zm14.816-.918l-4.364 5.143 1.144.97 4.364-5.143-1.144-.97zm-6.678 3.914V8.857h-1.5v3.429h1.5zM22.25 19A3.25 3.25 0 0119 22.25v1.5A4.75 4.75 0 0023.75 19h-1.5zM19 1.75A3.25 3.25 0 0122.25 5h1.5A4.75 4.75 0 0019 .25v1.5zM1.75 5A3.25 3.25 0 015 1.75V.25A4.75 4.75 0 00.25 5h1.5zM.25 19A4.75 4.75 0 005 23.75v-1.5A3.25 3.25 0 011.75 19H.25z"/>
      </svg>
    )
  }
}
