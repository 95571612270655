import {Component, Watch} from "vue-property-decorator";
import {VNode} from "vue";
import {BIconArrowLeft, BIconArrowRight} from "bootstrap-vue";
import FormMixin from "@/mixins/FormMixin";
import Wizard from "@/components/Wizard/Wizard";
import {authStore, serviceAndDemandStore} from "@/store";
import DemandStep1 from "@/_modules/demand/components/DemandStep1";
import DemandStep2 from "@/_modules/demand/components/DemandStep2";
import DemandStep3 from "@/_modules/demand/components/DemandStep3";
import DemandStep4 from "@/_modules/demand/components/DemandStep4";
import {Demand, DemandForm, DemandValidation} from "@/_modules/demand/types";
import {inferValidationInstance} from "@/utils/vuelidate-extension";
import {FileData, FilePublishStatePayload} from "@/types";
import {buildDemandForm} from "@/_modules/demand/form-builder";
import {Validations} from "vuelidate-property-decorators";
import {validationDemandRules} from "@/_modules/demand/validation";
import {servicesStore} from "@/_modules/service/store/services";
import {BasicErrorHandler} from "@/utils/errorHandler";
import * as Error from "@/utils/errors";
import {ErrorCodes} from "@/constants/APIconstants";
import {attachmentsErrors} from "@/components/Attachments";
import {profileStore} from "@/_modules/profile/store/profile";
import {demandStore} from "@/_modules/demand/store/demands";
import {isEmpty} from "@/constants/DataBoundaries";
import AttentionPopup from "@/components/layout/AttentionPopup";
import * as ProfileRoutes from "@/_modules/profile/router/routes";
import * as ActivityRoutes from "@/_modules/activity/router/routes";
import _, {cloneDeep} from "lodash";
import {v4 as uuidv4} from "uuid";
import { EventBus } from "@/main";
import { CustomEvents } from "@/constants/ComponentEvents";
import PageHeader from "@/components/layout/PageHeader";
import ContentSection from "@/components/layout/ContentSection";

export interface DemandComponentValidation {
  form: DemandValidation;
  //topLevelCategory: ValidationRuleSet;
}

const errors = {
  demandErrors: {
    noProfile: "err_user_does_not_have_profile",
    operationNotAuthorized: "err_operation_is_not_authorized"
  },
  attachmentsErrors: attachmentsErrors
}

export class DemandErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.UserDoesNotHaveProfile:
        this.errorMessageKey = errors.demandErrors.noProfile
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = errors.demandErrors.operationNotAuthorized
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const demandErrorHandler = new DemandErrorHandler()


const localeRoot = 'demand'
@Component({name: 'DemandLayout', components: {BIconArrowLeft, BIconArrowRight}})
export default class DemandLayout extends FormMixin {

  //private topLevelCategory = ''
  private form: DemandForm = buildDemandForm()
  private files: FileData[] = []
  private showAttention: boolean = false
  public fileUploadInProgress = false

  @Validations()
  public validations(): DemandComponentValidation {
    return validationDemandRules()
  }

  @Watch('form.requestedAtClientPlace')
  public async preFillAddress(): Promise<void> {
    if (this.form.requestedAtClientPlace) {
      const resp = await this.withRequest(profileStore.getProfile(authStore.authInfo!.userId!))
      if (isEmpty(this.form.location!.address!.address) && resp && resp!.location) {
        this.form.location = resp!.location!
      }
    }
  }


  public async mounted(): Promise<void> {
    if (!this.demandId) {
      const steps = _.cloneDeep({
        ...serviceAndDemandStore.demandStepData1,
        ...serviceAndDemandStore.demandStepData2,
        ...serviceAndDemandStore.demandStepData3,
        ...serviceAndDemandStore.demandStepData4
      })
      this.form = {
        ...buildDemandForm(),
        ...steps
      }
    }

    const userId = authStore.authInfo?.userId
    if (userId !== undefined) {
      try {
        const profile = await this.withRequest(profileStore.getProfile(userId), demandErrorHandler)
        if (profile !== undefined) {
          if (this.demandId !== undefined) {
            const resp = await this.withRequest(demandStore.getDemandById(this.demandId), demandErrorHandler)

            this.form = {
              ...buildDemandForm(),
              ...resp
            }

            const uploadedFiles = await this.withRequest(servicesStore.getUploadedFiles(this.form.uploadToken as string), demandErrorHandler)
            this.files.push(...uploadedFiles.map((obj: FileData) => obj))
          } else {
            // this.form.clientSex = profile.sex as Sex
            this.form.location = profile.location!
          }
        }
      } catch(e) {
        console.error(e)
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const profile = await this.withRequest(profileStore.getProfile(userId)).catch(e => Promise.resolve(undefined))
      this.showAttention = profile === undefined
    }

    if (!this.form.uploadToken) {
      this.form.uploadToken = uuidv4()
    }
  }

  public beforeDestroy(): void {
    serviceAndDemandStore.clearDemandData()
    serviceAndDemandStore.setCurrentDemandStep(0)
  }

  private get demandId(): string | undefined {
    return this.$route.params.demandId
  }

  private async onSubmit(): Promise<void> {

    if (this.checkValidation(this.$v.form)) {

      const demand = cloneDeep(this.form) as Demand

      demand.visible = this.form.visible === undefined ? true : this.form.visible
      demand.clientMentalAbility = isEmpty(demand.clientMentalAbility) ? undefined : demand.clientMentalAbility

      demand.budget = isEmpty(demand.budget?.price.amount) ? undefined : demand.budget
      if (this.demandId !== undefined) {
        await this.withRequest(demandStore.updateDemand({demandId: this.demandId, data: demand}), demandErrorHandler)
      } else {
        await this.withRequest(demandStore.addDemand(demand), demandErrorHandler)
      }
    }
  }

  private async changePublishState(payload: FilePublishStatePayload): Promise<void> {
    await this.withRequest(servicesStore.setFileVisibility(payload), demandErrorHandler)
  }

  private validateFirstStep(): boolean {
    const fieldsToValidate = [this.$v.form.title, this.$v.form.clientRequirements, this.$v.form.demandDescription_ru, this.$v.form.demandDescription_et, this.$v.form.demandDescription_en, this.$v.form.category]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private validateSecondStep(): boolean {
    const fieldsToValidate = [this.$v.form.clientSex, this.$v.form.clientAge, this.$v.form.clientWeight, this.$v.form.clientPhysicalActivity, this.$v.form.languages]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private validateThirdStep(): boolean {
    const fieldsToValidate = [this.$v.form.location, this.$v.form.agreeToPublishAddress]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private validateFourthStep(): boolean {
    const fieldsToValidate = [this.$v.form.budget]
    const res = fieldsToValidate.flatMap(field => this.checkValidation(field))
    return !res.some(field => !field)
  }

  private setDataToStore(): void {
    switch (serviceAndDemandStore.currentDemandStep) {
      case 1:
        if (this.validateFirstStep()) {
          serviceAndDemandStore.setDemandStep1({
            userId: this.form.userId,
            demandId: this.form.demandId,
            clientRequirements: this.form.clientRequirements,
            category: this.form.category!,
            title: this.form.title,
            demandDescription_ru: this.form.demandDescription_ru,
            demandDescription_en: this.form.demandDescription_en,
            demandDescription_et: this.form.demandDescription_et,
            uploadToken: this.form.uploadToken
          })
        }
        break
      case 2:
        if (this.validateSecondStep()) {
          serviceAndDemandStore.setDemandStep2({
            clientSex: this.form.clientSex,
            clientAge: this.form.clientAge,
            clientWeight: this.form.clientWeight,
            clientMentalAbility: this.form.clientMentalAbility,
            clientPhysicalActivity: this.form.clientPhysicalActivity,
            clientDisease: this.form.clientDisease,
            languages: this.form.languages
          })
        }
        break
      case 3:
        if (this.validateThirdStep()) {
          serviceAndDemandStore.setDemandStep3({
            location: this.form.location,
            agreeToPublishAddress: this.form.agreeToPublishAddress,
            requestedAtClientPlace: this.form.requestedAtClientPlace
          })
        }
        break
      case 4:
        if (this.validateFourthStep()) {
          serviceAndDemandStore.setDemandStep4({
            budget: this.form.budget,
            visible: this.form.visible
          })
        }
        break
      default:
        break
    }
  }

  private scrollToTop(): void {
    const wizard = document.querySelector('.wizard')
    if (wizard !== null && wizard.getBoundingClientRect().top < 0) {
      wizard.scrollIntoView({behavior: "auto", block: "start"})
    }
  }

  private async onPrevClick(): Promise<void> {
    await this.touchSession()
    serviceAndDemandStore.setCurrentDemandStep(Math.max(serviceAndDemandStore.currentDemandStep - 1, 0))
    this.setDataToStore()
    this.scrollToTop()
  }

  private async onNextClick(): Promise<void> {
    await this.touchSession()
    switch (serviceAndDemandStore.currentDemandStep) {
      case 0:
        if (this.validateFirstStep()) {
          serviceAndDemandStore.setCurrentDemandStep(serviceAndDemandStore.currentDemandStep + 1)
          this.setDataToStore()
          this.scrollToTop()
        }
        break
      case 1:
        if (this.validateSecondStep()) {
          serviceAndDemandStore.setCurrentDemandStep(serviceAndDemandStore.currentDemandStep + 1)
          this.setDataToStore()
          this.scrollToTop()
        }
        break
      case 2:
        if (this.validateThirdStep()) {
          serviceAndDemandStore.setCurrentDemandStep(serviceAndDemandStore.currentDemandStep + 1)
          this.setDataToStore()
          this.scrollToTop()
        }
        break
      case 3:
        if (this.validateFourthStep()) {
          this.setDataToStore()
          await this.onSubmit()
          serviceAndDemandStore.setCurrentDemandStep(serviceAndDemandStore.currentDemandStep + 1)
          await this.$router.push(ActivityRoutes.Demands)

          if (this.form.demandId !== undefined) {
            this.toastMessagesPayload.successToastMessageKey = 'msg_demand_updated'
          } else {
            this.toastMessagesPayload.successToastMessageKey = 'msg_demand_added'
          }
    
          EventBus.$emit(CustomEvents.ToastMsg, this.toastMessagesPayload)
          EventBus.$on(CustomEvents.DismissToast, () => {
            this.toastMessagesPayload.successToastMessageKey = null
          })
        }
        break
      default:
        break
    }
  }

  private formattedAttentionMessage(): VNode {
    return (
      <div>
        {this.translation('msg_warning_new_demand_profile_not_filled_up')}
      </div>
    )
  }

  public render(): VNode {
    const currentStep = serviceAndDemandStore.currentDemandStep
    const v = inferValidationInstance<DemandComponentValidation>(this.$v)
    return (
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            headerClass="mt-4 mb-6 mb-md-10"
            title={this.demandId === undefined ? this.translation('title_add_demand') : this.translation('title_edit_demand')}
            wideHeader={true}
          >
            {this.demandId === undefined &&
              <p>{this.translation('lead_add_demand')}</p>
            }
          </PageHeader>
        </b-row>
        <Wizard localeRoot={localeRoot} currentStep={serviceAndDemandStore.currentDemandStep}/>
        <ContentSection aria-live="true">
          {currentStep === 0 &&
            <DemandStep1
              v={v}
              value={this.form}
              files={this.files}
              localeRoot={localeRoot}
              //topLevelCategory={this.topLevelCategory}
              onSubCategoryChange={(c: string) => (this.form.category = c)}
              onTopCategoryChange={(cat: string) => (this.form.category = cat)}
              onFileAdd={(file: FileData) => this.files.push(file)}
              onAttachmentsError={(err: string) => (this.errorMessageKey = err)}
              onFileUploadInProgress={(p: boolean) => (this.fileUploadInProgress = p)}
              onChangePublishState={(payload: FilePublishStatePayload) => this.changePublishState(payload)}
            />
          }
          {currentStep === 1 &&
            <DemandStep2
              localeRoot={localeRoot}
              value={this.form}
              v={v}
            />
          }
          {currentStep === 2 &&
            <DemandStep3
              localeRoot={localeRoot}
              value={this.form}
              v={v}
            />
          }
          {currentStep === 3 &&
            <DemandStep4
              class="pb-md-1"
              localeRoot={localeRoot}
              value={this.form}
              v={v}
              onCurrencyInputFormatted={(amount: string) => (this.form.budget.price.amount = amount)}
            />
          }
          <hr class="mt-1 mb-6 mt-md-6" />
          <div class="d-flex flex-column flex-sm-row justify-content-end">
            {currentStep !== 0 &&
              <b-button
                class="mb-2 mb-sm-0 mr-sm-2"
                onClick={this.onPrevClick}
                variant="outline-primary"
                disabled={this.busy}
              >
                <b-icon-arrow-left aria-hidden="true" class="app-icon-lg mr-2" />
                {this.translation('shared.btn_prev')}
              </b-button>
            }
            <b-button
              onClick={this.onNextClick}
              variant="primary"
              disabled={this.busy}
            >
              {this.translation(currentStep !== 3 ? 'shared.btn_next' : 'btn_save')}
              <b-icon-arrow-right aria-hidden="true" class="app-icon-lg ml-2" />
            </b-button>
          </div>
        </ContentSection>
        <AttentionPopup
          message={this.formattedAttentionMessage}
          btnNextLabel={'btn_change_profile'}
          btnNextPath={ProfileRoutes.Profile.path}
          btnNext={true}
          btnBack={true}
          btnClose={false}
          show={this.showAttention}
        />
      </b-container>
    )
  }
}
