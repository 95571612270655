import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

type ValueType = string[] | number[] | boolean[]

interface PropsType extends AbstractInputPropsType<ValueType> {
  title: string;
  options: object[] | string[];
  valueField?: string;
  textField?: string;
  stacked?: boolean;
  groupClass?: string;
  checkBoxClass?: string;
}

@Component({name: "CheckBoxGroupFieldset"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {

  @Prop(String) public readonly title!: string
  @Prop({type: Array, required: false, default: () => []}) public readonly options!: object[] | string[]
  @Prop({type: String, required: false, default: 'value'}) public readonly valueField!: string
  @Prop({type: String, required: false, default: 'text'}) public readonly textField!: string
  @Prop([Array]) public readonly value?: ValueType
  @Prop({type: Boolean, required: false, default: true}) public readonly stacked?: boolean
  @Prop({type: String, default: 'mb-5 mb-md-9', required: false}) public readonly groupClass?: string
  @Prop({type: String, default: undefined, required: false}) public readonly checkBoxClass?: string

  public isEmpty(): boolean {
    return this.value === undefined || this.value.length === 0
  }

  public render(): VNode {
    return (
      <b-form-group
        class={this.groupClass}
        description={this.description}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        <legend class="h3 fieldset-title">{this.title}</legend>
        <b-form-checkbox-group
          stacked={this.stacked}
          id={this.id}
          name={this.name}
          required={this.required}
          disabled={this.disabled}
          state={this.state}
          checked={this.value}
          options={this.options}
          size="md"
          onInput={this.onInput}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          class={this.checkBoxClass}
        />
      </b-form-group>
    )
  }

}
