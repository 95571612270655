import {Component, Prop} from "vue-property-decorator"
import Vue, {VNode} from "vue"
import {AbstractInputComponent} from "@/components/form/AbstractInputComponent";
import {ServiceTypeSubCategoriesWithQualification} from "@/_modules/service/types";
import {appStore} from "@/store";
import {Language} from "@/types";
import {BIconStopCircleFill} from "bootstrap-vue";
import CheckBoxGroupFieldset from "./CheckBoxGroupFieldset";
import AppIconClose from "@/components/icons/AppIconClose"

type ValueType = string[] | string | number | boolean | undefined

interface Props {
  readonly buttonLabel: string;
  readonly buttonClass?: string;
  readonly options: ServiceTypeSubCategoriesWithQualification[];
  readonly invalidMessage?: string;
  readonly value: string[];
  readonly onInput?: (v: string[]) => void;
  readonly onChange?: (v: string[]) => void;
  readonly onConfirm?: (v: string[]) => void;
  readonly onCancel?: () => void;
  readonly disabled: boolean;
  modalTitle?: string;
  label?: string;
}

@Component({name: 'SubCategorySelectModal', components: {BIconStopCircleFill}})
export default class extends AbstractInputComponent<ValueType, Props> {
  @Prop(String) public readonly buttonLabel!: string
  @Prop({type: String, default: ''}) public readonly buttonClass?: string
  @Prop({type: Array, required: false, default: () => []}) public readonly options!: ServiceTypeSubCategoriesWithQualification[]
  @Prop(Array) public value!: string[]
  @Prop(String) public modalTitle?: string
  @Prop(String) public label?: string

  private selectedCategories: {[key: string]: string[]} = {}

  public isEmpty(): boolean {
    // Checkbox can't be empty
    return false
  }

  public get state(): boolean | undefined {
    return this.invalidMessage !== undefined ? false : undefined
  }

  public get selectedCategoriesArray(): string[] {
    return Object.values(this.selectedCategories).flat()
  }

  public getTranslation(type: ServiceTypeSubCategoriesWithQualification): string {
    switch (appStore.locale) {
      case Language.RUS:
        return type.category_ru
      case Language.EST:
        return type.category_et
      case Language.ENG:
        return type.category_en
      default:
        return type.category_et
    }
  }

  public mounted(): void {
    this.options.map((option) => {
      Vue.set(this.selectedCategories, option.category, []);
    });
  }
  
  public render(): VNode {
    let modalTitle = this.modalTitle;

    if (modalTitle === undefined) {
      modalTitle = this.buttonLabel;
    }

    return (
      <div class="service-type-toggle">
        <b-form-group
          label={this.label}
          state={this.state}
          invalid-feedback={this.invalidMessage}
          class={this.state ? "is-invalid" : ""}
        >
          <b-button 
            disabled={this.disabled}
            v-b-modal={this.id}
            variant="block"
            class={`dropdown-toggle ${this.buttonClass!}`}
          >
            {this.buttonLabel}
          </b-button>
          <b-modal
            cancel-title={this.translation('btn_reject')}
            cancel-variant="outline-primary"
            ok-title={this.translation('btn_confirm')}
            centered
            id={this.id}
            scrollable
            size="xl"
            title={modalTitle}
            title-tag="h2"
            no-close-on-backdrop
            onCancel={() => this.$emit('cancel')}
            onOk={() => this.$emit('confirm', this.selectedCategoriesArray)}
          >
            <template slot="modal-header-close">
              <AppIconClose class="text-primary" />
            </template>
            <template slot="default">
              {this.options.map((option, index) => {
                return (
                  <CheckBoxGroupFieldset
                    disabled={this.disabled}
                    groupClass={index === this.options.length - 1 ? 'mb-0' : undefined}
                    title={this.getTranslation(option)}
                    options={(option.subCategories.map((type) => {
                      return {
                        value: type.category,
                        text: this.getTranslation(type)
                      }
                    }))}
                    value={this.selectedCategories[option.category]}
                    onChange={(values: string[]) => {
                      Vue.set(this.selectedCategories, option.category, values);
                    }}
                  />
                )
              })}
            </template>
          </b-modal>
        </b-form-group>
      </div>
    )
  }
}