import {Module, VuexModule, Action, getModule} from "vuex-module-decorators"
import rootStore from "@/store"

import axios from "@/api"
import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"

import {UnexpectedInternalError, UnexpectedServerResponseError} from "@/utils/errors"
import {Demand, DemandCards, DemandCriteria, DemandDetails, DemandDetailsPayload, DemandUpdate} from "../types"
import {FileData} from "@/types";

import queryString, {StringifiableRecord} from "query-string"

interface DemandData {
  category?: string;
  sortBy?: string;
  sortMode?: string;
  page?: number;
  size?: number;
  lat?: number;
  lon?: number;
  dist?: number;
  dlat?: number;
  dlon?: number;
}

function toDemandQuery(criteria: DemandCriteria): DemandData {
  const result: DemandData = {
    sortBy: criteria.order?.columnName,
    sortMode: criteria.order?.orderDirection,
    page: criteria.pagination?.current === undefined ? undefined : criteria.pagination.current - 1,
    size: criteria.pagination?.size,
    lat: criteria.coordinates?.latitude,
    lon: criteria.coordinates?.longitude,
    dlat: criteria.existLocationCords?.dLat,
    dlon: criteria.existLocationCords?.dLon
  }

  if (criteria.filters !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyResult: any = result
    for (const [key, value] of criteria.filters.entries()) {
      anyResult[key] = value
    }
  }
  return result
}

@Module({dynamic: true, namespaced: true, name: "demands", store: rootStore})
export class DemandsModule extends VuexModule {

  @Action({rawError: true})
  public async addDemand(demand: Demand): Promise<void> {
    const resp = await axios.post(`${BackendUrls.demands}`, demand)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
  }

  @Action({rawError: true})
  public async updateDemand(request: DemandUpdate): Promise<void> {
    if (request.demandId === undefined) {
      throw new UnexpectedInternalError("demandId is not set")
    }
    const resp = await axios.put(`${BackendUrls.demands}/${request.demandId}`, request.data)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
  }

  @Action({rawError: true})
  public async getDemands(request: DemandCriteria): Promise<DemandCards> {
    const url = BackendUrls.demands + '?' + queryString.stringify(toDemandQuery(request) as StringifiableRecord)
    const resp = await axios.get<DemandCards>(url)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getDemandById(demandId: string): Promise<Demand> {
    const resp = await axios.get<Demand>(`${BackendUrls.demands}/${demandId}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }
  
  @Action({rawError: true})
  public async getDemandDetailsById(demandDetailsPayload: DemandDetailsPayload): Promise<DemandDetails> {
    const demandId = demandDetailsPayload.demandId
    const lat = demandDetailsPayload.lat
    const lon = demandDetailsPayload.lon
    const resp = await axios.get<DemandDetails>(`${BackendUrls.demands}/${demandId}/details?lat=${lat}&lon=${lon}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getUploadedFiles(uploadToken: string): Promise<FileData[]> {
    const resp = await axios.get(`${BackendUrls.files}/${uploadToken}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }
}

export const demandStore = getModule(DemandsModule, rootStore)