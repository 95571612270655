import {Component, Prop} from 'vue-property-decorator'
import {VNode} from 'vue'
import {TsxComponent} from '@/components/TsxComponent'

interface MultiLangTabsPayload {
  demandDescription_ru?: string;
  demandDescription_et?: string;
  demandDescription_en?: string;
  /*diseaseDescription_ru?: string;
  diseaseDescription_et?: string;
  diseaseDescription_en?: string;*/
  description_ru?: string;
  description_et?: string;
  description_en?: string;
  scheduleInfo_ru?: string;
  scheduleInfo_et?: string;
  scheduleInfo_en?: string;
}

interface Props {
  value: MultiLangTabsPayload;
}
@Component({name: 'MultiLangTabs'})
export default class MultiLangTabs extends TsxComponent<Props> {
  @Prop(Object) public readonly value!: MultiLangTabsPayload

  public render(): VNode {
    return (
      <b-row class="multi-tab-textarea readonly">
        <b-col cols="12">
          <b-tabs>
            {this.value.demandDescription_et &&
            <b-tab title={this.translation('text_EST')}>
              <p>
                {this.value.demandDescription_et}
              </p>
            </b-tab>
            }
            {this.value.demandDescription_ru &&
            <b-tab title={this.translation('text_RUS')}>
              <p>
                {this.value.demandDescription_ru}
              </p>
            </b-tab>
            }
            {this.value.demandDescription_en &&
            <b-tab title={this.translation('text_ENG')}>
              <p>
                {this.value.demandDescription_en}
              </p>
            </b-tab>
            }

            {/* {this.value.diseaseDescription_et &&
            <b-tab title={this.translation('text_EST')}>
              <p>
                {this.value.diseaseDescription_et}
              </p>
            </b-tab>
            }
            {this.value.diseaseDescription_ru &&
            <b-tab title={this.translation('text_RUS')}>
              <p>
                {this.value.diseaseDescription_ru}
              </p>
            </b-tab>
            }
            {this.value.diseaseDescription_en &&
            <b-tab title={this.translation('text_ENG')}>
              <p>
                {this.value.diseaseDescription_en}
              </p>
            </b-tab>
            } */}

            {this.value.description_et &&
            <b-tab title={this.translation('text_EST')}>
              <p>
                {this.value.description_et}
              </p>
            </b-tab>
            }
            {this.value.description_ru &&
            <b-tab title={this.translation('text_RUS')}>
              <p>
                {this.value.description_ru}
              </p>
            </b-tab>
            }
            {this.value.description_en &&
            <b-tab title={this.translation('text_ENG')}>
              <p>
                {this.value.description_en}
              </p>
            </b-tab>
            }

            {this.value.scheduleInfo_et &&
            <b-tab title={this.translation('text_EST')}>
              <p>
                {this.value.scheduleInfo_et}
              </p>
            </b-tab>
            }
            {this.value.scheduleInfo_ru &&
            <b-tab title={this.translation('text_RUS')}>
              <p>
                {this.value.scheduleInfo_ru}
              </p>
            </b-tab>
            }
            {this.value.scheduleInfo_en &&
            <b-tab title={this.translation('text_ENG')}>
              <p>
                {this.value.scheduleInfo_en}
              </p>
            </b-tab>
            }
          </b-tabs>
        </b-col>
      </b-row>
    )
  }
}
