import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconGenderAmbiguous'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="app-icon" viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.06 6.705l4.145-4.144v4.152a.75.75 0 101.5 0V.75a.75.75 0 00-.75-.75h-5.963a.75.75 0 100 1.5h4.152L15 5.644l1.06 1.06z" fill="currentColor"/>
        <circle cx="12" cy="10" r="5" stroke="currentColor" fill="none" stroke-width="1.5"/>
        <path d="M12.75 15.5a.75.75 0 00-1.5 0h1.5zm-1.5 7.75a.75.75 0 001.5 0h-1.5zm4.75-3.5a.75.75 0 000-1.5v1.5zm-8-1.5a.75.75 0 000 1.5v-1.5zm3.25-2.75V19h1.5v-3.5h-1.5zm0 3.5v4.25h1.5V19h-1.5zm.75.75h4v-1.5h-4v1.5zm0-1.5H8v1.5h4v-1.5z" fill="currentColor"/>
      </svg>
    )
  }
}
