import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent"
import { BIconCheck2 } from "bootstrap-vue";

export interface WizardIconDefinition {
  content: string;
  active: boolean;
  stepCompleted: boolean;
}

interface Props {
  icon: WizardIconDefinition;
}

@Component({
  name: 'WizardIcon',
  components: {
    BIconCheck2
  }
})
export default class WizardIcon extends TsxComponent<Props> {
  @Prop(Object) public readonly icon!: WizardIconDefinition

  public render(): VNode {
    return (
      <div class="wizard-icon">
        {!this.icon.stepCompleted && this.icon.content}
        {this.icon.stepCompleted && <b-icon-check2 aria-label={this.icon.content} class="app-icon-lg m-0"/>}
      </div>
    )
  }
}
